/**
 * Created by osirvent on 22/07/2016.
 */
angular
    .module('annexaApp')
    .factory('CustomFieldFactory',['$q', '$http','Language', 'HelperService', '$filter', '$rootScope', 'globalModals', 'AnnexaFormlyFactory', '$state', 'RestService', 'GlobalDataFactory', 'CommonAdminModals', 'CacheFactory', 'CommonService', function($q, $http, Language, HelperService, $filter, $rootScope, globalModals, AnnexaFormlyFactory, $state, RestService, GlobalDataFactory, CommonAdminModals, CacheFactory, CommonService) {
        var factory = {};
        factory.procedure = {};
        factory.procedureStartProfilesAux = [];
        factory.procedureTramitationProfilesAux = [];
        factory.procedureViewProfilesAux = [];
        factory.procedureRolesInterestedAux = [];
        factory.procedureResponsibleProfilesAux = [];
        factory.customFieldsProcedure = [];
        factory.customFields = [];
        factory.customOk = [];
        factory.classificationBoxs = [];
        factory.classificationTree = {};
        factory.transactionTypesAux = [];
        factory.createTransactionTypesAux = [];
        factory.subproceduresAux = [];
		factory.documentationToProvide = [];
		factory.footerClaimsAux = [];
		
        factory.getModelValues = function(model) {
            var cf = [];
            angular.forEach(model, function(value, key) {
                if(key.startsWith('cf_')) {
                    if(value && value instanceof Date) {
                        cf.push({id: key, value: new Date(Date.UTC(value.getFullYear(),value.getMonth(),value.getDate(),00,00,00))});
                    }else{
                        if(value && value.$selected){
                        	cf.push({id: key, value: value.$selected.id});
                        }else{
                        	cf.push({id: key, value: value});
                        }
                    }
                }
            });

            return cf;
        };
        factory.addFormlyFieldRow = function(form, customField, required, id, noEditable, parent, objectParent, inConfig, parentObjectCustomField, cffields) {
            var data = {
                row: true,
                colClass: ' col-sm-12',
                labelClass: 'label-strong'
            };

            var selectedCustomFieldSelect = function ($item, options) {
            	selectedCustomFieldSelectChangeValue($item);
            	$rootScope.$broadcast('customFieldSelectSelected', { customField: customField.id, selectedValue: $item.id });
            };
            var selectedCustomFieldSelectChangeValue = function ($item, options) {
            	if(parentObjectCustomField && parentObjectCustomField.relatedCustomFields && parentObjectCustomField.relatedCustomFields.length > 0){
					var okRelated = true;
					var lvalue = undefined;
					if(customField && (customField.backendType === 'MULTIPLESELECT')){
						if(form && form.model && form.model['cf_'+customField.id] && form.model['cf_'+customField.id].length != 1){
							okRelated = false;
						}
						if(okRelated){
							if(customField && customField.listValues && form && form.model && form.model['cf_'+customField.id]){
								lvalue = $linq(customField.listValues).firstOrDefault(undefined, "x=> x.id == "+form.model['cf_'+customField.id][0]);
							}
						}
					}else if(customField && (customField.backendType === 'CHECKBOX')){
						if(form && form.model){
							if(customField && customField.listValues){
								var size = 0;
								_.forEach(customField.listValues, function(lvcf){
									if(form.model['cf_'+customField.id+'_'+lvcf.id]){
										size = size+1;
										lvalue = lvcf;
									}
								});
								if(size != 1){
									okRelated = false;
									lvalue = undefined;
								}
							}else{
								okRelated = false;
								lvalue = undefined;
							}
						}
					}else{
						if(okRelated){
							if(customField && customField.listValues){
								lvalue = $linq(customField.listValues).firstOrDefault(undefined, "x=> x.id == "+$item.id);
							}
						}
					}
					var relatedCFs = $linq(cffields).intersect(parentObjectCustomField.relatedCustomFields, "(x,y) => x.customFieldTemplateTag != undefined && y.customField != undefined && y.customField.templateTag != undefined && y.customField.templateTag === x.customFieldTemplateTag").toArray();
					if(lvalue && relatedCFs && relatedCFs.length > 0){
						_.forEach(relatedCFs, function(related){
							if(related.customField && related.customField.templateTag){
								if(lvalue[related.customField.templateTag]){
									try{
										if(related.customField.backendType === 'DATETIME'){
											try{
												var dateParts = lvalue[related.customField.templateTag].split("/");
						                        var dateObject = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
						    					if(dateObject != "Invalid Date") {
						    						form.model['cf_'+related.customField.id] = dateObject;
						    					} else {
						    						dateParts = HelperService.dateMaskToDate(dateParts);
						    						form.model['cf_'+related.customField.id] = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
						    					}
											}catch(e){
												var dateTime = Date.parse(lvalue[related.customField.templateTag]);
												if(dateTime){
													form.model['cf_'+related.customField.id] = new Date(dateTime);
												}else{
													form.model['cf_'+related.customField.id] = undefined;
												}
											}
										}else if(related.customField.frontendType == 'INPUT' && related.customField.backendType === 'INTEGER'){
											form.model['cf_'+related.customField.id] = parseInt(lvalue[related.customField.templateTag]);
										}else if(related.customField.frontendType == 'INPUT' && related.customField.backendType === 'DECIMAL'){
											var valueLiteral_parts = (lvalue[related.customField.templateTag]).split('.');
					                        if(valueLiteral_parts && valueLiteral_parts.length > 0){
					                            if(valueLiteral_parts[1]){
					                                if(related.customField.decimalPositions){
					                                	form.model['cf_'+related.customField.id] =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0."+valueLiteral_parts[1]).toFixed(related.customField.decimalPositions));
					                                }else{
					                                	form.model['cf_'+related.customField.id] =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0."+valueLiteral_parts[1]).toFixed(2));
					                                }
					                            }else{
					                                if(related.customField.decimalPositions){
					                                	form.model['cf_'+related.customField.id] =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0.00").toFixed(related.customField.decimalPositions));
					                                }else {
					                                	form.model['cf_'+related.customField.id] =  parseFloat(parseFloat(valueLiteral_parts[0]).toLocaleString() + ".00");
					                                }
					                            }
					                        }
										}else if(related.customField.frontendType == 'INPUT' && related.customField.backendType === 'ADDRESS'){
											//TODO no pot arribar un address des de BBDD
										}else if((related.customField.frontendType === 'SELECT' || related.customField.frontendType === 'SELECT_LINKED'  || related.customField.frontendType === 'RADIO')){
											form.model['cf_'+related.customField.id] = parseInt(lvalue[related.customField.templateTag]);
										} else if(related.customField.frontendType === 'MULTIPLESELECT'){
											form.model['cf_'+related.customField.id] = [parseInt(lvalue[related.customField.templateTag])];
										} else if(related.customField.frontendType === 'CHECKBOX'){
											form.model['cf_'+related.customField.id+'_'+parseInt(lvalue[related.customField.templateTag])] = true;
										} else if(related.customField.frontendType === 'STRUCTURAL_SELECT'){
											var aux = parseInt(lvalue[related.customField.templateTag]);
											if(aux){
												var lvaux = $linq(customField.listValues).firstOrDefault(undefined, "x => x.id == aux");
												if(lvaux){
													form.model['cf_'+related.customField.id] = {id: lvaux.id, title:lvaux[Language.getActiveColumn()]}		;											
												}
											}
										}
									}catch(e){
										if(customField && (customField.backendType === 'MULTIPLESELECT')){
											form.model['cf_'+related.customField.id] = [];
										}else if(customField && (customField.backendType === 'CHECKBOX')){
											if(form && form.model){
												if(customField && customField.listValues){
													_.forEach(customField.listValues, function(lvcf){
														form.model['cf_'+related.customField.id+'_'+lvcf.id] = false;
													});
												}
											}
										}else{
											form.model['cf_'+related.customField.id] = undefined;
										}
									}
								}else{
									if(customField && (customField.backendType === 'MULTIPLESELECT')){
										form.model['cf_'+related.customField.id] = [];
									}else if(customField && (customField.backendType === 'CHECKBOX')){
										if(form && form.model){
											if(customField && customField.listValues){
												_.forEach(customField.listValues, function(lvcf){
													form.model['cf_'+related.customField.id+'_'+lvcf.id] = false;
												});
											}
										}
									}else{
										form.model['cf_'+related.customField.id] = undefined;
									}
								}
							}
						});
					}
				}
            };
            switch (customField.frontendType) {
                case 'INPUT':
                    var type = 'text';
                    var step = undefined;

                    switch (customField.backendType) {
                        case 'INTEGER':
                            type = 'number';
                            step = '1';
                            break;
                        case 'DECIMAL':
                            type = 'number';
                            step = '0.' + Array(customField.decimalPositions).join('0') + '1';
                            break;
                    }

                    if(customField.backendType == 'DATETIME') {
                        var datepickerOptions = {
                            format: 'dd/MM/yyyy',
                            initDate: new Date(),
                            showWeeks: false,
                            startingDay: 1
                        }

                        form.addField(
                            'cf_' + id,
                            'annexaDatepickerRow',
                            '',
                            new AnnexaFormlyFieldDatepickerTemplateOptions(
                                'text',
                                customField[Language.getActiveColumn()],
                                required,
                                datepickerOptions
                            ),
                            data,
                            ((!noEditable)?"false":"true"));

                    } else if(customField.backendType == 'ADDRESS') {
                    	
                    	form.addField(
                    		'cf_' + id,
		                    'annexaHidden',
		                    '',
		                    new AnnexaFormlyFieldTemplateOptions(
		                        'hidden',
		                        '',
		                        required
		                    ),
		                    data,
		                    undefined
		                );
                    	form.addField(
                            'cfValue_' + id,
                            'annexaInputRow',
                            '',
                            new AnnexaFormlyFieldTemplateOptions(
                                type,
                                customField[Language.getActiveColumn()],
                                required,
                                false,
                                step,
                                900
                            ),
                            data,
                            ((!noEditable)?"false":"true"));
                    	form.addField(
                                'addressBtn_' + id,
                                'annexaLabelButton',
                                '',
                                new AnnexaFormlyFieldLabelButtonTemplateOptions(
                                    'text',
                                    undefined,
                                    customField[Language.getActiveColumn()],
                                    undefined,undefined,undefined,
                                    'btn-sm',id,
                                    undefined,undefined
                                ),
                                data,
                                ((!noEditable)?"false":"true"));
                    } else {
                        form.addField(
                            'cf_' + id,
                            'annexaInputRow',
                            '',
                            new AnnexaFormlyFieldTemplateOptions(
                                type,
                                customField[Language.getActiveColumn()],
                                required,
                                false,
                                step,
                                900
                            ),
                            data,
                            ((!noEditable)?"false":"true"));
                    }
                    break;
                case 'JSON':
                case 'TEXTAREA':
                    form.addField(
                        'cf_' + id,
                        'annexaTextAreaRow',
                        '',
                        new AnnexaFormlyFieldTextAreaTemplateOptions(
                            customField[Language.getActiveColumn()],
                            5,
                            required,
                            undefined,
                            9999
                        ),
                        data,
                        ((!noEditable)?"false":"true"));
                    break;
                case 'SELECT':
                    form.addField(
                        'cf_' + id,
                        'annexaSelectRow',
                        '',
                        new AnnexaFormlyFieldSelectTemplateOptions(
                            customField[Language.getActiveColumn()],
                            'value',
                            Language.getActiveColumn(),
                            customField.listValues,
                            required,
                            undefined,
                            selectedCustomFieldSelect
                        ),
                        data,
                        ((!noEditable)?"false":"true"),
                        undefined,
                        undefined,
                        customField,
                        $rootScope
                    );
                    break;
                case 'SELECT_LINKED':
                    var controllerFunction = function ($scope, $http) {
                		if(customField && customField.fromQuery && customField.query && customField.query.id && customField.listValues && customField.listValues.length > 0){
                			$scope.to.options =  customField.listValues;
                		}
                        $scope.$on('customFieldSelectSelected', function (event, args) {
                        	if(args.customField && customField && customField.linkedCustomField && customField.linkedCustomField.id === args.customField){
	                        	if(customField && customField.fromQuery && customField.query && customField.query.id){
	                        		if(args.customField && args.selectedValue && parent && inConfig != undefined && objectParent && parentObjectCustomField && parentObjectCustomField.identifierField && parentObjectCustomField.labelPropField && args.selectedValue) {
	                        			var url = "";
	                        			if(objectParent === "PROCEDURE"){
	                        				url = './api/procedures/calculateCustomFields/'+parent.id+'/'+inConfig+'/'+customField.id+'/'+args.selectedValue+'/'+args.customField;
	                        			}else if(objectParent === "TASK_TYPE"){
	                        				url = './api/task_type/calculateCustomFields/'+parent.id+'/'+inConfig+'/'+customField.id+'/'+args.selectedValue+'/'+args.customField;
	                        			}else if(objectParent === "DOCUMENT_TYPE"){
	                        				url = './api/doc/document_type/calculateCustomFields/'+parent.id+'/'+inConfig+'/'+customField.id+'/'+args.selectedValue+'/'+args.customField;
	                        			}
	                        			if(url && url !== ""){
	                        				$http({method: 'GET',url: url}).then(function(data){
		                            		  if(data){
		                            			  var opts = ((data && data.data && data.data.length > 0)?JSOG.decode(data.data):[]);
		                            			  var newopts = [];
		                            			  _.forEach(opts, function(lv){
		                            					if(lv[parentObjectCustomField.identifierField]){
		                            						var label = '';
		                            						var labelPropFieldS = parentObjectCustomField.labelPropField.split("--@--@--");
		                            						_.forEach(labelPropFieldS, function(lp){
		                            							label = label + ((label)?((parentObjectCustomField.labelFieldsSeparator)?parentObjectCustomField.labelFieldsSeparator:'')+" "+((lv[lp])?lv[lp]:''):((lv[lp])?lv[lp]:''));
		                            						});
		                            						newopts.push({id: lv[parentObjectCustomField.identifierField], language1:label, language2:label, language3:label, value:lv[parentObjectCustomField.identifierField] });
		                            					}
		                            				});
		                            		  }
		                            		  $scope.options.resetModel();
		                                      $scope.to.options = newopts;
	                        				}).catch(function(error){
	                        					$scope.options.resetModel();
	                                            $scope.to.options = [];
	                        				});
	                        				
	                        			 } else {
	                                         $scope.options.resetModel();
	                                         $scope.to.options = [];
	                                     }
	                                } else {
	                                    $scope.options.resetModel();
	                                    $scope.to.options = [];
	                                }
	                        	}else if(args.customField && args.selectedValue) {
	                                var options = $linq(customField.listValues).where("x => x.linkedCustomFieldId == " + args.customField + " && x.parentValue.id == " + args.selectedValue).toArray();
	
	                                $scope.options.resetModel();
	                                $scope.to.options = options;
	                            } else if (args.customField && !args.selectedValue) {
	                                $scope.options.resetModel();
	                                $scope.to.options = [];
	                            }
                        	}
                        });
                    }

                    form.addField(
                        'cf_' + id,
                        'annexaSelectRow',
                        '',
                        new AnnexaFormlyFieldSelectTemplateOptions(
                            customField[Language.getActiveColumn()],
                            'value',
                            Language.getActiveColumn(),
                            [], // customField.listValues,
                            required,
                            undefined,
                            selectedCustomFieldSelectChangeValue
                        ),
                        data,
                        ((!noEditable)?"false":"true"),
                        undefined,
                        controllerFunction
                    );
                    break;
                case 'MULTIPLESELECT':
                    form.addField(
                        'cf_' + id,
                        'annexaMultipleSelectRow',
                        '',
                        new AnnexaFormlyFieldSelectTemplateOptions(
                            customField[Language.getActiveColumn()],
                            'value',
                            Language.getActiveColumn(),
                            customField.listValues,
                            required,
                            undefined,
                            selectedCustomFieldSelectChangeValue
                        ),
                        data,
                        ((!noEditable)?"false":"true")
                    );
                    break;
                case 'CHECKBOX':
                    form.addField(
                        'cf_' + id,
                        'annexaRadioCheckboxRow',
                        '',
                        new AnnexaFormlyFieldSelectTemplateOptions(
                            customField[Language.getActiveColumn()],
                            'value',
                            Language.getActiveColumn(),
                            customField.listValues,
                            required,
                            'checkbox',
                            selectedCustomFieldSelectChangeValue
                        ),
                        data,
                        ((!noEditable)?"false":"true")
                    );
                    break;
                case 'RADIO':
                    form.addField(
                        'cf_' + id,
                        'annexaRadioCheckboxRow',
                        '',
                        new AnnexaFormlyFieldSelectTemplateOptions(
                            customField[Language.getActiveColumn()],
                            'value',
                            Language.getActiveColumn(),
                            customField.listValues,
                            required,
                            'radio',
                            selectedCustomFieldSelectChangeValue
                        ),
                        data,
                        ((!noEditable)?"false":"true")
                    );
                    break;
                case 'STRUCTURAL_SELECT':
                	var options = angular.copy(customField.listValues); 
                	_.forEach(options, function(val){
                		if(val.parentValue && val.parentValue.id){
                			val.parent = {id:val.parentValue.id};
                		}
                	});
                	options = CommonService.getTreeData(options, Language.getActiveColumn());
                	form.addField(
            			'cf_' + id,
                		'annexaSelectTreeRow',
                		'',
                		new AnnexaFormlyFieldSelectTemplateOptions(
                			customField[Language.getActiveColumn()],
            				'value',
            				Language.getActiveColumn(),
            				options,
            				required,
            				undefined,
            				selectedCustomFieldSelectChangeValue
                		),
                		data,
                        ((!noEditable)?"false":"true")
                    );
                    break;
                case 'CF_GROUP':
            		var groupData = { 
            			extra:{
            				seeSelectedLinked:true,
    						valuesFromLinkedCustomFields:{parentId:((parent)?parent.id:undefined), objectParent:objectParent, inConfig:inConfig}
            			}
            		}
            		var cfToUpdate = $linq(cffields).firstOrDefault(undefined, "x => x.customField && x.customField.id == "+customField.id);
            		if(cfToUpdate){
	            		form.addField(
	            			'cf_' + id+'_Label',
	                		'annexaLabel',
	                		'',
	                		{label:customField[Language.getActiveColumn()], value:undefined, labelClass: 'label-strong'},
	                		{row: false, colClass: ' col-sm-12', labelClass: 'label-strong'},
	                        ((!noEditable)?"false":"true")
	                    );
	                	form.addField(
	            			'cf_' + id,
	                		'annexaComponent',
	                		'',
	                		{type: 'annexa-object-custom-fields-group'},
	                		{
	                			groupData: groupData,
	                			customField:cfToUpdate
	                		},
	                        ((!noEditable)?"false":"true")
	                    );
            		}
                    break;
            }

            return form;
        };
        factory.getCustomFields = function(){
            var deferrend = $q.defer();
            $http({
                method: 'GET',
                url: './api/procedures/getProcedure',
                data: { idProcedure: null }
            }).success(function(data, status) {
                deferrend.resolve(data);
            }).error(function(msg,code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        }
        
        factory.enableOrDisableCustomFields = function(cfId){
            var deferrend = $q.defer();
            $http({
                method: 'PUT',
                url: './api/custom_fields/enableOrDisable/'+cfId
            }).success(function(data, status) {
                deferrend.resolve(data);
            }).error(function(msg,code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        }
        
        factory.getProcedure = function (idProcedure) {
            return $http({
                url: './api/procedures/getProcedure',
                method: 'GET',
                params: { idProcedure: idProcedure }
            }).then(function (data) {
                if (data && data.data){
                    var response = JSOG.decode(data.data);
                    factory.procedure = response.procedure;
                    if(!factory.procedure.archiveClassification){
                        factory.procedure.archiveClassification = {};
                    }
                    factory.customFields = response.customFields;
                    angular.forEach(response.customFieldsDisable, function(value, key){
                        var aux = HelperService.findFirstElementWhere(factory.customFields, 'id', value.id, -1);
                        if(aux != -1){
                            aux.disableElement = true;
                        }
                    });
                    factory.customFields = $linq(factory.customFields).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
                    factory.customFields.unshift({id:"-1", language1:$filter('translate')('global.literals.new'), language2:$filter('translate')('global.literals.new'), language3:$filter('translate')('global.literals.new')});
                    if(factory.procedure.procedureStartProfiles){
                        factory.procedureStartProfilesAux.length = 0;
                        angular.forEach(factory.procedure.procedureStartProfiles, function(value, key){
                            factory.procedureStartProfilesAux.push(value.profile.id);
                        });
                    }
                    if(factory.procedure.procedureTramitationProfiles){
                        factory.procedureTramitationProfilesAux.length = 0;
                        angular.forEach(factory.procedure.procedureTramitationProfiles, function(value, key){
                            factory.procedureTramitationProfilesAux.push(value.profile.id);
                        });
                    }
                    if(factory.procedure.procedureViewProfiles){
                        factory.procedureViewProfilesAux.length = 0;
                        angular.forEach(factory.procedure.procedureViewProfiles, function(value, key){
                            factory.procedureViewProfilesAux.push(value.profile.id)
                        });
                    }
                    if(factory.procedure.procedureRoleInterested){
                        factory.procedureRolesInterestedAux.length = 0;
                        angular.forEach(factory.procedure.procedureRoleInterested, function(value, key){
                            factory.procedureRolesInterestedAux.push(value.roleInterested.id)
                        });
                    }
                    if(factory.procedure.procedureResponsibleProfiles){
                        factory.procedureResponsibleProfilesAux.length = 0;
                        angular.forEach(factory.procedure.procedureResponsibleProfiles, function(value, key){
                            factory.procedureResponsibleProfilesAux.push(value.profile.id);
                        });
                    }
                    if(factory.procedure.transactionTypes){
                        factory.transactionTypesAux.length = 0;
                        angular.forEach(factory.procedure.transactionTypes, function(value, key){
                            factory.transactionTypesAux.push(value.transactionType.id)
                        });
                        if(factory.transactionTypesAux && factory.transactionTypesAux.length > 0){
                        	factory.transactionTypesAux = $linq(factory.transactionTypesAux).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
                        }
                    }
                    if(factory.procedure.createTransactionTypes){
                        factory.createTransactionTypesAux.length = 0;
                        angular.forEach(factory.procedure.createTransactionTypes, function(value, key){
                            factory.createTransactionTypesAux.push(value.transactionType.id)
                        });
                        if(factory.createTransactionTypesAux && factory.createTransactionTypesAux.length > 0){
                        	factory.createTransactionTypesAux = $linq(factory.createTransactionTypesAux).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
                        }
                    }
                    if(factory.procedure.subprocedures){
                        factory.subproceduresAux.length = 0;
                        angular.forEach(factory.procedure.subprocedures, function(value, key){
                            factory.subproceduresAux.push(value.subprocedure.id)
                        });
                        if(factory.subproceduresAux && factory.subproceduresAux.length > 0){
                        	factory.subproceduresAux = $linq(factory.subproceduresAux).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
                        }
                    }
                    if(factory.procedure.footerClaims){
                        factory.footerClaimsAux.length = 0;
                        angular.forEach(factory.procedure.footerClaims, function(value, key){
                            factory.footerClaimsAux.push(value.footerClaim.id)
                        });
                        if(factory.footerClaimsAux && factory.footerClaimsAux.length > 0){
                        	factory.footerClaimsAux = $linq(factory.footerClaimsAux).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
                        }
                    }
                    if(factory.procedure.customFields){
                    	var calculateRequiredStringGroups = function(groups){
                    		if(groups && groups.length > 0){
                    			_.forEach(groups, function(group){
                    				group.requiredString = 'OPTIONAL';
                                    if(group.required){
                                    	group.requiredString = 'REQUIRED';
                                    }else if(group.requiredForEndDossier){
                                    	group.requiredString = 'REQUIRED_TO_END';
                                    }else if(group.hiddenField){
                                    	group.requiredString = 'HIDDEN_FIELD';
                                    }else if(group.noEditable) {
                                    	group.requiredString = 'NO_EDITABLE';
                                    }
                                    if(group.groups && group.groups.length > 0){
                                    	calculateRequiredStringGroups(group.groups);
                                    }
                    			});
                    		}
                    	}
						var customsOk = [];
                        factory.customFieldsProcedure.length = 0;
	 					var customFieldsAux = $linq(factory.procedure.customFields).orderBy("x => x.viewOrder").toArray();
                       	angular.forEach(customFieldsAux, function (value, key) {
							var customField = {
                                    customField: value.customField,
                                    id: value.id,
                                    delete: value.deleted,
                                    viewOrder:value.viewOrder,
                                    required: value.required,
                                    requiredForEndDossier: value.requiredForEndDossier,
                                    hiddenField: value.hiddenField,
                                    noEditable: value.noEditable,
                                    procedure: {id:factory.procedure.id},
                                    createdDate: value.createdDate,
                                    value: value.value,
									customFieldType: value.customFieldType,
									transactionTypes: value.transactionTypes,
			                        descriptionLanguage1: value.descriptionLanguage1,
			                        descriptionLanguage2: value.descriptionLanguage2,
			                        descriptionLanguage3: value.descriptionLanguage3,
			                        nameLanguage1: value.nameLanguage1,
			                        nameLanguage2: value.nameLanguage2,
			                        nameLanguage3: value.nameLanguage3,
			                        conditional: value.conditional,
			                        spel: value.spel,
			                        spelDossier: value.spelDossier,
			                        identifierField: value.identifierField,
			                        labelPropField:value.labelPropField,
			                        labelFieldsSeparator:value.labelFieldsSeparator,
			                        parentField:value.parentField,
			                        queryParams: value.queryParams,
			                        relatedCustomFields: value.relatedCustomFields,
			                        calculated:value.calculated,
			                        dynamicallyCalculated: value.dynamicallyCalculated,
			                        spelCalculated: value.spelCalculated,
			                        spelCalculatedDossier: value.spelCalculatedDossier,
			                        canPublish: value.canPublish,
			                        groups: value.groups
                                };
                                customField.requiredString = 'OPTIONAL';
                                if(value.required){
                                    customField.requiredString = 'REQUIRED';
                                }else if(value.requiredForEndDossier){
                                    customField.requiredString = 'REQUIRED_TO_END';
                                }else if(value.hiddenField){
                                    customField.requiredString = 'HIDDEN_FIELD';
                                }else if(value.noEditable) {
                                    customField.requiredString = 'NO_EDITABLE';
                                }
                                calculateRequiredStringGroups(customField.groups);
                                factory.customFieldsProcedure.push(customField);
                                customsOk.push(value.customField.id);
                        });
						if(customsOk && customsOk.length > 0) {
                            factory.customFields = $linq(factory.customFields).except(customsOk, "(x, y) => x == y.id ").toArray();
                        }
					}
                    if(response.classifications){
                        factory.classificationBoxs.length = 0;
                        angular.forEach(response.classifications, function(value, key){
                            factory.classificationBoxs.push(value);
                        });
                    }
					if(response.documentationToProvide){
						factory.documentationToProvide = response.documentationToProvide;
						if(factory.documentationToProvide && factory.documentationToProvide.length > 0){
							_.forEach(factory.documentationToProvide, function(dtp){
								if(dtp.procedure){
									dtp.procedure = {id: dtp.procedure.id};
								}
							});
						}
					}else{
						facotry.documentationToProvide = [];
					}
                }else{
                    $state.transitionTo('annexa.admin.unguided_procedure');
                }
            }).catch(function (error) {
				factory.documentationToProvide = [];
                $state.transitionTo('annexa.admin.unguided_procedure');
            });
        }

        factory.calculateValueCustomField = function(field, model, id){
            if(field && field.customField){
                if(field.customField.backendType  == 'DATETIME' && field.value){
                    var dateParts = field.value.split("/");
                    var dateObject = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]); // month
																								// is
																								// 0-based
                    return dateObject;
                }else  if (field.customField.frontendType == 'INPUT' && field.customField.backendType == 'INTEGER') {
                    if(field.value){
                        return  parseInt(field.value);
                    }
                } else if (field.customField.frontendType == 'INPUT' && field.customField.backendType == 'DECIMAL') {
                    if(field.value){
                        var valueLiteral_parts = (field.value+'').split('.');
                        var finalValue = 0.00;
                        if(valueLiteral_parts && valueLiteral_parts.length > 0){
                            if(valueLiteral_parts[1]){
                                if(field.customField.decimalPositions){	 
                                    finalValue =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0."+valueLiteral_parts[1]).toFixed(field.customField.decimalPositions));
                                }else{
                                    finalValue =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0."+valueLiteral_parts[1]).toFixed(2));
                                }
                            }else{
                                if(field.customField.decimalPositions){
                                    finalValue =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0.00").toFixed(field.customField.decimalPositions));
                                }else {
                                    finalValue =  parseFloat(parseFloat(valueLiteral_parts[0]).toLocaleString() + ".00");
                                }
                            }
                        }
                        return finalValue;
                    }
                } else if(field.customField.frontendType == 'RADIO' || field.customField.frontendType == 'SELECT'){
                    if(field.value && field.customField.listValues && $linq(field.customField.listValues).indexOf("x => x.id == "+field.value) > -1){
                        return $linq(field.customField.listValues).first("x => x.id == "+field.value).value;
                    }
                }else if(field.customField.frontendType == 'CHECKBOX'){
                    if(field.customField.listValues){
                        var values = [];
                        if(field.value && Array.isArray(field.value)){
							values = field.value;
							_.forEach(field.customField.listValues, function(option){
	                            if( model != undefined ) {
	                                if (_.contains(values, option['value'])) {
	                                    model[id + '_' + option.value] = true;
	                                } else {
	                                    model[id + '_' + option.value] = false;
	                                }
	                            }
	                        });
						}else if(field.value){
                            values = field.value.split(',');
							_.forEach(field.customField.listValues, function(option){
	                            if( model != undefined ) {
	                                if (_.contains(values, "" + option['id'])) {
	                                    model[id + '_' + option.value] = true;
	                                } else {
	                                    model[id + '_' + option.value] = false;
	                                }
	                            }
	                        });
                        }
                    }
                    return undefined;
                }else if(field.customField.frontendType == 'MULTIPLESELECT'){
                    var finalValue = [];
                    if(field.customField.listValues){
                        var values = [];
                        if(field.value && Array.isArray(field.value)){
							values = field.value;
							_.forEach(values, function(option){
	                            if($linq(field.customField.listValues).indexOf("x => x.value == "+option) > -1) {
	                                finalValue.push($linq(field.customField.listValues).first("x => x.value == " + option).value);
	                            }
	                        });
						}else if(field.value){
                            values = field.value.split(',');
							_.forEach(values, function(option){
	                            if($linq(field.customField.listValues).indexOf("x => x.id == "+option) > -1) {
	                                finalValue.push($linq(field.customField.listValues).first("x => x.id == " + option).value);
	                            }
	                        });
                        }
                    }
                    return finalValue;
                }else if(field.customField.frontendType == 'STRUCTURAL_SELECT'){
                	var actualModel = {};
                	if(field.value) {
                		if(field.customField && field.customField.listValues){
                			var opt = $linq(field.customField.listValues).firstOrDefault(undefined, "x => x.id == "+field.value)
                			if(opt){
                				actualModel.$selected = {id:opt.id, title:opt[Language.getActiveColumn()]};
                			}
                		}
                	}
                	return actualModel;
                }
            }
            return field.value;
        }

		factory.calculateValueCustomFieldDossier = function(field){
            if(field && field.customField){
                if(field.customField.backendType  == 'DATETIME' && field.value){
                    if(field.value instanceof Date){
                        return new Date(Date.UTC(field.value.getFullYear(),field.value.getMonth(),field.value.getDate(),00,00,00));
                    }else{
                    	var dateParts = field.value.split("/");
                    	var dateObject = new Date(Date.UTC(dateParts[2], dateParts[1] - 1, dateParts[0], 00,00,00)); // month is 0-based
                    	return dateObject;
                    }
                }else  if (field.customField.frontendType == 'INPUT' && field.customField.backendType == 'INTEGER') {
                    if(field.value){
                        return  parseInt(field.value);
                    }
                } else if (field.customField.frontendType == 'INPUT' && field.customField.backendType == 'DECIMAL') {
                    if(field.value){
                        var valueLiteral_parts = (field.value+'').split('.');
                        var finalValue = 0.00;
                        if(valueLiteral_parts && valueLiteral_parts.length > 0){
                            if(valueLiteral_parts[1]){
                                if(field.customField.decimalPositions){	 
                                    finalValue =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0."+valueLiteral_parts[1]).toFixed(field.customField.decimalPositions));
                                }else{
                                    finalValue =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0."+valueLiteral_parts[1]).toFixed(2));
                                }
                            }else{
                                if(field.customField.decimalPositions){
                                    finalValue =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0.00").toFixed(field.customField.decimalPositions));
                                }else {
                                    finalValue =  parseFloat(parseFloat(valueLiteral_parts[0]).toLocaleString() + ".00");
                                }
                            }
                        }
                        return finalValue;
                    }
                } else if(field.customField.frontendType == 'RADIO' || field.customField.frontendType == 'SELECT'  || field.customField.frontendType == 'SELECT_LINKED'){
                    if(field.value && field.customField.listValues && $linq(field.customField.listValues).indexOf("x => x.id == "+field.value) > -1){
                        return $linq(field.customField.listValues).first("x => x.id == "+field.value).value;
                    }
                }else if(field.customField.frontendType == 'CHECKBOX' || field.customField.frontendType == 'MULTIPLESELECT'){
                    var finalValue = [];
                    if(field.customField.listValues){
                        var values = [];
                        if(field.value && Array.isArray(field.value)){
							values = field.value;
							_.forEach(values, function(option){
	                            if($linq(field.customField.listValues).indexOf("x => x.value == "+option) > -1) {
	                                finalValue.push($linq(field.customField.listValues).first("x => x.value == " + option).value);
	                            }
	                        });
						}else if(field.value){
							if('string' === (typeof field.value)){
								values = field.value.split(',');
								_.forEach(values, function(option){
		                            if($linq(field.customField.listValues).indexOf("x => x.id == "+option) > -1) {
		                                finalValue.push($linq(field.customField.listValues).first("x => x.id == " + option).value);
		                            }
		                        });
							}else{
								if($linq(field.customField.listValues).indexOf("x => x.id == "+field.value) > -1) {
	                                finalValue.push($linq(field.customField.listValues).first("x => x.id == " + field.value).value);
	                            }
							}
                        }
                    }
                    return finalValue;
                }else if(field.customField.backendType  == 'ADDRESS' && field.value){
                	 return angular.fromJson(field.value);
                } 
            }
            return field.value;
        }
        
		factory.calculateValueJSONCustomField = function(field){
		    if(field.value){
                var object = undefined;
                try{
                	object = angular.fromJson(field.value.replace(/(?:\r\n|\r|\n)/g, '<br/>'));
                    if(object.value && object.value.replace) {
                    	field.valueFromJSON = object.value.replace(/<br\s*\/?>/gi, '\n');
                    }else {
                    	field.valueFromJSON = object.value;
                    }
                    if(field.customField.backendType == "DATETIME"){
                    	if(field.valueFromJSON){
                    		field.valueFromJSON = new Date(field.valueFromJSON);
                    	}
                    }
                    if(field.customField.frontendType === 'STRUCTURAL_SELECT'){
                    	var selected = undefined;
                    	var options = angular.copy(field.customField.listValues); 
                    	_.forEach(options, function(val){
                    		if(val.parentValue && val.parentValue.id){
                    			val.parent = {id:val.parentValue.id};
                    		}
                    	});
                    	options = CommonService.getTreeData(options, Language.getActiveColumn());
                        var found = false;
                        if(options){
                            angular.forEach(options, function (val, key) {
                                 if (!found) {
                                     selected = $linq(val).singleOrDefault(undefined, "x => x.id == " + field.valueFromJSON);
                                     if (selected) {
                                         found = true;
                                     }
                                 }
                            });
                        }
                        field.valueFromJSON = {
                			model: ((selected)?{$selected: selected}:{}),
                            options: options,
                            required: field.required
                    	}
                    }
                } catch (error) {
                    if(field.customField.frontendType == 'MULTIPLESELECT' || field.customField.frontendType == 'CHECKBOX'){
                    	field.valueFromJSON = [];
                    }else if(field.customField.frontendType === 'STRUCTURAL_SELECT'){
                    	var options = angular.copy(field.customField.listValues); 
                    	_.forEach(options, function(val){
                    		if(val.parentValue && val.parentValue.id){
                    			val.parent = {id:val.parentValue.id};
                    		}
                    	});
                    	options = CommonService.getTreeData(options, Language.getActiveColumn());
                    	field.valueFromJSON = {
                			model: {},
                            options: options,
                            required: value.required
                    	}
                    }else{
                    	field.valueFromJSON = '';
                    }
                }
            }else{
                if(field.customField.frontendType == 'MULTIPLESELECT' || field.customField.frontendType == 'CHECKBOX'){
                	field.valueFromJSON = [];
                }else if(field.customField.frontendType === 'STRUCTURAL_SELECT'){
                	var options = angular.copy(field.customField.listValues); 
                	_.forEach(options, function(val){
                		if(val.parentValue && val.parentValue.id){
                			val.parent = {id:val.parentValue.id};
                		}
                	});
                	options = CommonService.getTreeData(options, Language.getActiveColumn());
                	field.valueFromJSON = {
            			model: {},
                        options: options,
                        required: value.required
                	}
                }else{
                	field.valueFromJSON = '';
                }
            }
            if (field && field.customField && field.customField.backendType == "DECIMAL"){
                if(field.customField.decimalPositions) {
                	field.step = '0.' + Array(field.customField.decimalPositions).join('0') + '1';
                }else{
                	field.step = '0.01'
                }
            }
    	}
		
		factory.calculateValueJSONGroupCustomField = function(field){
			if(field && field.value){
            	field.valueFromJSON = field.value;
            	try{
                	if(field.relatedCustomField.backendType == "DATETIME"){
                    	if(field.valueFromJSON){
                    		field.valueFromJSON = new Date(field.valueFromJSON);
                    	}
                    }else  if (field.relatedCustomField.frontendType == 'INPUT' && (field.relatedCustomField.backendType == 'INTEGER')) {
                    	field.valueFromJSON = parseInt(field.valueFromJSON);
                    } else if (field.relatedCustomField.frontendType == 'INPUT' && field.relatedCustomField.backendType == 'DECIMAL') {
                        var valueLiteral_parts = (field.valueFromJSON).split('.');
                        if(valueLiteral_parts && valueLiteral_parts.length > 0){
                            if(valueLiteral_parts[1]){
                                if(field.relatedCustomField.decimalPositions){
                                	field.valueFromJSON =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0."+valueLiteral_parts[1]).toFixed(field.relatedCustomField.decimalPositions));
                                }else{
                                	field.valueFromJSON =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0."+valueLiteral_parts[1]).toFixed(2));
                                }
                            }else{
                                if(field.relatedCustomField.decimalPositions){
                                	field.valueFromJSON =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0.00").toFixed(field.relatedCustomField.decimalPositions));
                                }else {
                                	field.valueFromJSON =  parseFloat(parseFloat(valueLiteral_parts[0]).toLocaleString() + ".00");
                                }
                            }
                        }
                    }  else if(field.relatedCustomField.frontendType == 'INPUT' && field.relatedCustomField.backendType == 'ADDRESS') {
                    	field.valueFromJSON = JSON.parse(field.valueFromJSON);
                    } else if(field.relatedCustomField.frontendType == 'RADIO' || field.relatedCustomField.frontendType == 'SELECT'  || (field.relatedCustomField.frontendType == 'SELECT_LINKED' && groupData.extra && groupData.extra.seeSelectedLinked === true)){
                        if(field.relatedCustomField.backendType == 'INTEGER'){
                        	field.valueFromJSON= parseInt(field.valueFromJSON);
                        }
                    }else if(field.relatedCustomField.frontendType == 'CHECKBOX' || field.relatedCustomField.frontendType == 'MULTIPLESELECT'){
                        var vals = [];
                        if(field.relatedCustomField.listValues){
                            var modelsSelected = field.valueFromJSON.split(',');
                            _.forEach(modelsSelected, function(option){
                                if(field.relatedCustomField.backendType == 'INTEGER' && option){
                                	vals.push(parseInt(option));
                                }else{
                                	vals.push(option);
                                } 
                            });
                        }
                        field.valueFromJSON = vals;
                    }else if(field.relatedCustomField.frontendType == 'STRUCTURAL_SELECT'){
                    	var selected = undefined;
                    	var options = angular.copy(field.relatedCustomField.listValues); 
                    	_.forEach(options, function(val){
                    		if(val.parentValue && val.parentValue.id){
                    			val.parent = {id:val.parentValue.id};
                    		}
                    	});
                    	options = CommonService.getTreeData(options, Language.getActiveColumn());
                        var found = false;
                        if(options){
                            angular.forEach(options, function (val, key) {
                                 if (!found) {
                                     selected = $linq(val).singleOrDefault(undefined, "x => x.id == " + field.valueFromJSON);
                                     if (selected) {
                                         found = true;
                                     }
                                 }
                            });
                        }
                        field.valueFromJSON = {
                			model: ((selected)?{$selected: selected}:{}),
                            options: options,
                            required: field.required
                    	}
                    }
            	}catch(e){
            		console.error(e);
            		if(field.relatedCustomField.frontendType == 'MULTIPLESELECT' || field.relatedCustomField.frontendType == 'CHECKBOX'){
                    	field.valueFromJSON = [];
                    }else if(field.relatedCustomField.frontendType === 'STRUCTURAL_SELECT'){
                    	var options = angular.copy(field.relatedCustomField.listValues); 
                    	_.forEach(options, function(val){
                    		if(val.parentValue && val.parentValue.id){
                    			val.parent = {id:val.parentValue.id};
                    		}
                    	});
                    	options = CommonService.getTreeData(options, Language.getActiveColumn());
                    	field.valueFromJSON = {
                			model: {},
                            options: options,
                            required: field.required
                    	}
                    }else{
                    	field.valueFromJSON = '';
                    }
            	}
            }else{
                if(field.relatedCustomField.frontendType == 'MULTIPLESELECT' || field.relatedCustomField.frontendType == 'CHECKBOX'){
                	field.valueFromJSON = [];
                }else if(field.relatedCustomField.frontendType === 'STRUCTURAL_SELECT'){
                	var options = angular.copy(field.relatedCustomField.listValues); 
                	_.forEach(options, function(val){
                		if(val.parentValue && val.parentValue.id){
                			val.parent = {id:val.parentValue.id};
                		}
                	});
                	options = CommonService.getTreeData(options, Language.getActiveColumn());
                	field.valueFromJSON = {
            			model: {},
                        options: options,
                        required: field.required
                	}
                }else{
                	field.valueFromJSON = '';
                }
            }
            if (field && field.relatedCustomField && field.relatedCustomField.backendType == "DECIMAL"){
                if(field.relatedCustomField.decimalPositions) {
                	field.step = '0.' + Array(field.relatedCustomField.decimalPositions).join('0') + '1';
                }else{
                	field.step = '0.01'
                }
            }
		}
		factory.customFieldModal = function(id, callFunctionOnSave){
			var openModal = function(decodeddata, isDisabled, queries){
				var saveCustomFieldFunction = function () {
					var addError = function(mod, error, update){
						if(mod){
							if(error && error.data && error.data.message){
								if(error.data.message.toUpperCase() === 'NO CONTENT TO INSERT'){
									mod.alerts.push({ msg: $filter('translate')('global.sec.errors.noContent')});
								}else if(error.data.message.toUpperCase() === 'NO BACKENDTYPE DATA RECIVED NOT FOUND'){
									mod.alerts.push({ msg: $filter('translate')('global.sec.errors.noBackendType')});
								}else if(error.data.message.toUpperCase() === 'NO FRONTENDTYPE DATA RECIVED NOT FOUND'){
									mod.alerts.push({ msg: $filter('translate')('global.sec.errors.noFrontendType')});
								}else if(error.data.message.toUpperCase() === 'NO NAME DATA RECIVED NOT FOUND'){
									mod.alerts.push({ msg: $filter('translate')('global.sec.errors.noName')});
								}else if(error.data.message.toUpperCase() === 'NO LIST VALUES DATA RECIVED NOT FOUND'){
									mod.alerts.push({ msg: $filter('translate')('global.errors.noListValues')});
								}else if(error.data.message.toUpperCase() === 'NO TEMPLATETAG DATA RECIVED NOT FOUND'){
									mod.alerts.push({ msg: $filter('translate')('global.sec.errors.noTemplateTag')});
								}else if(error.data.message.toUpperCase() === 'TEMPLATETAG DUPLICATED'){
									mod.alerts.push({ msg: $filter('translate')('global.errors.customFieldTemplateTagDuplicate')});
								}else if(error.data.message.toUpperCase() === 'TEMPLATETAG HAS TO START WITH A LOWERCASE LETTER'){
									mod.alerts.push({ msg: $filter('translate')('global.errors.customFieldTemplateTagNoStartWithLetter')});
								}else if(error.data.message.toUpperCase() === 'TEMPLATETAG CAN ONLY CONTAIN A-ZA-Z0-9_'){
									mod.alerts.push({ msg: $filter('translate')('global.errors.customFieldTemplateTagIncorrectCharacters')});
								}else if(error.data.message.toUpperCase() === 'NO CONTENT TO UPDATE'){
									mod.alerts.push({ msg: $filter('translate')('global.sec.errors.noContent')});
								}else if(error.data.message.toUpperCase() === 'PROCEDURE PROPOSAL ID NOT EXIST'){
									mod.alerts.push({ msg: $filter('translate')('global.sec.errors.noCustomField')});
								}else{
									if(update){
										mod.alerts.push({ msg: $filter('translate')('global.errors.customFieldError')});
									}else{
										mod.alerts.push({ msg: $filter('translate')('global.errors.unknownNew')});
									}	
								}
							}else{
								if(update){
									mod.alerts.push({ msg: $filter('translate')('global.errors.customFieldError')});
								}else{
									mod.alerts.push({ msg: $filter('translate')('global.errors.unknownNew')});
								}
							}
						}
					}
					var self = this;
	                var data = this.annexaFormly.model.modal_body;
	                data.pressButton = true;
	                if(this.annexaFormly.form['modal.annexaFormly.form_annexaLanguageFieldSet_listValues_4']) {
	                	this.annexaFormly.form['modal.annexaFormly.form_annexaLanguageFieldSet_listValues_4'].$validate();
	                }

	                if(this.annexaFormly.form.$valid) {
	                	var newCustomField = {};
	                	if(this.extra && this.extra.id){
	                		newCustomField = this.extra;
	                	}
	                	newCustomField.language1 = data.language1,
	                	newCustomField.language2 = data.language2,
	                	newCustomField.language3 = data.language3,
	                	newCustomField.descriptionLanguage1 = data.descriptionLanguage1,
	                	newCustomField.descriptionLanguage2 = data.descriptionLanguage2,
	                	newCustomField.descriptionLanguage3 = data.descriptionLanguage3,
	                	newCustomField.nameLanguage1 = data.nameLanguage1,
	                	newCustomField.nameLanguage2 = data.nameLanguage2,
	                	newCustomField.nameLanguage3 = data.nameLanguage3,
	                	newCustomField.active = data.active,
	                	newCustomField.backendType = data.backendType,
	                	newCustomField.frontendType = data.frontendType,
	                	newCustomField.templateTag = data.templateTag,
	                	newCustomField.fromQuery =((data.fromQuery && (newCustomField.frontendType == 'SELECT' || newCustomField.frontendType == 'MULTIPLESELECT' || newCustomField.frontendType == 'CHECKBOX' || newCustomField.frontendType == 'RADIO' || newCustomField.frontendType == 'SELECT_LINKED' || newCustomField.frontendType == 'SELECT_LINKED' || newCustomField.frontendType == 'STRUCTURAL_SELECT'))?true:false),
	                	newCustomField.linkedCustomField = ((data.linkedCustomField  && newCustomField.frontendType == 'SELECT_LINKED')?((data.linkedCustomField.id)?{id:data.linkedCustomField.id}:{id:data.linkedCustomField}):undefined),
	                	newCustomField.query = ((data.fromQuery && data.query && (newCustomField.frontendType == 'SELECT' || newCustomField.frontendType == 'MULTIPLESELECT' || newCustomField.frontendType == 'CHECKBOX' || newCustomField.frontendType == 'RADIO' || newCustomField.frontendType == 'SELECT_LINKED' || newCustomField.frontendType == 'SELECT_LINKED' || newCustomField.frontendType == 'STRUCTURAL_SELECT'))?((data.query.id)?{id:data.query.id}:{id:data.query}):undefined),
	                	newCustomField.listValues = []
	                	var listValuesOk = true;
	                    if(!newCustomField.fromQuery && (newCustomField.frontendType == 'SELECT' || newCustomField.frontendType == 'MULTIPLESELECT' || newCustomField.frontendType == 'CHECKBOX' || newCustomField.frontendType == 'RADIO')){
	                    	if(data.listValues && data.listValues.length > 0){
	                    		newCustomField.listValues = data.listValues;
	                    	}else{
	                    		self.alerts.push({ msg: $filter('translate')('global.errors.noListValues')});
	                    		listValuesOk = false;
	                    	}
	                    }
	                    if(!newCustomField.fromQuery && (newCustomField.frontendType == 'SELECT_LINKED')){
							var lcfValueField = $linq(this.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'listValuesLinked'");
							if(lcfValueField && lcfValueField.data && lcfValueField.data.listValues && lcfValueField.data.listValues.length > 0){
	                    		newCustomField.listValues = lcfValueField.data.listValues;
	                    		_.forEach(newCustomField.listValues, function(lv){
	                    			if(lv.parentValue && lv.parentValue.id){
	                    				lv.parentValue = {id:lv.parentValue.id, language1:lv.parentValue.language1, language2:lv.parentValue.language2, language3:lv.parentValue.language3};
	                    			}
	                    		});
	                    	}else{
	                    		self.alerts.push({ msg: $filter('translate')('global.errors.noListValues')});
	                    		listValuesOk = false;
	                    	}
	                    }
	                    if(!newCustomField.fromQuery && (newCustomField.frontendType == 'STRUCTURAL_SELECT')){
	                    	var lcfValueField = $linq(this.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'listValuesTree'");
							if(lcfValueField && lcfValueField.data && lcfValueField.data.listValues && lcfValueField.data.listValues.length > 0){
	                    		newCustomField.listValues = lcfValueField.data.listValues;
	                    		_.forEach(newCustomField.listValues, function(lv){
	                    			if(lv.parentValue && lv.parentValue.id){
	                    				lv.parentValue = {id:lv.parentValue.id, language1:lv.parentValue.language1, language2:lv.parentValue.language2, language3:lv.parentValue.language3};
	                    			}
	                    		});
	                    	}else{
	                    		self.alerts.push({ msg: $filter('translate')('global.errors.noListValues')});
	                    		listValuesOk = false;
	                    	}
	                    }
	                    if(newCustomField.frontendType == 'CF_GROUP'){
	                    	var gcfValueField = $linq(this.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'customFieldsGroup'");
							if(gcfValueField && gcfValueField.data && gcfValueField.data.customFieldList && gcfValueField.data.customFieldList.length > 0){
	                    		newCustomField.groups = [];
	                    		_.forEach(gcfValueField.data.customFieldList, function(gcf){
	                    			var gcfAux = angular.copy(gcf);
	                    			if(gcfAux.customField && gcfAux.customField.id){
	                    				gcfAux.customField = {id:gcfAux.customField.id};
	                    			}
	                    			if(gcfAux.relatedCustomField && gcfAux.relatedCustomField.id){
	                    				gcfAux.relatedCustomField = {id:gcfAux.relatedCustomField.id};
	                    			}
	                    			newCustomField.groups.push(gcfAux);
	                    		});
	                    	}else{
	                    		self.alerts.push({ msg: $filter('translate')('global.errors.noListValues')});
	                    		listValuesOk = false;
	                    	}
	                    }else{
	                    	newCustomField.groups = [];
	                    }
	                    if(listValuesOk){
	                    	if(newCustomField && newCustomField.id){
	                    		RestService.update('./api/custom_fields/' + id, newCustomField).then(function (data) {
	                    			if(callFunctionOnSave){
	                    				callFunctionOnSave(JSOG.decode(data));
	                    			}
	                    			if(GlobalDataFactory.customFields){
	                    				GlobalDataFactory.customFields.length = 0;
		                   			}else{
		                   				GlobalDataFactory.customFields = [];
		                   			}
		                   			if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('customFields')){
		                   				CacheFactory.get('globalDataCache').remove('customFields')
		                   			}
		                   			GlobalDataFactory.loadGlobalData();
	                    			self.close();
	                            }).catch(function (error) {
	                            	addError(self, error, true);
	                            });
	                    	}else{
	                    		RestService.insert('./api/custom_fields/', newCustomField).then(function (data) {
	                    			if(callFunctionOnSave){
	                    				callFunctionOnSave(JSOG.decode(data));
	                    			}
	                    			if(GlobalDataFactory.customFields){
	                    				GlobalDataFactory.customFields.length = 0;
		                   			}else{
		                   				GlobalDataFactory.customFields = [];
		                   			}
		                   			if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('customFields')){
		                   				CacheFactory.get('globalDataCache').remove('customFields')
		                   			}
		                   			GlobalDataFactory.loadGlobalData();
	                    			self.close();
	                            }).catch(function (error) {
	                            	addError(self, error, false);
	                            });
	                    	}
	                    }
	                } else {
	                    if(this.annexaFormly.form['modal.annexaFormly.form_annexaLanguageFieldSet_listValues_4'].$error.required) {
	                        self.alerts.push({ msg: $filter('translate')('global.errors.noListValues')})
	                    }
	                }
	                data.pressButton = undefined;
	                if(this.annexaFormly.form['modal.annexaFormly.form_annexaLanguageFieldSet_listValues_4']) {
	                    this.annexaFormly.form['modal.annexaFormly.form_annexaLanguageFieldSet_listValues_4'].$validate();
	                }
		        }
                var modal = angular.copy(CommonAdminModals.customFields);
                modal.alerts = [];
                modal.isDisabled = isDisabled;
                var queryField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'query'");
                if(queryField && queryField.templateOptions){
                	queryField.templateOptions.options = ((queries)?queries:[]); 
                }
                var cfField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'linkedCustomField'");
                if(cfField && cfField.templateOptions){
                	var opts = ((GlobalDataFactory.customFields)?$linq(GlobalDataFactory.customFields).where("x => x.id != "+id+" && (x.frontendType == 'SELECT_LINKED' || x.frontendType == 'SELECT' || x.frontendType == 'RADIO' || x.frontendType == 'STRUCTURAL_SELECT')").select(function (x) { return {id:x.id, language1:x.language1, language2:x.language2, language3:x.language3}; }).toArray():[]);
                	cfField.templateOptions.options = $linq(opts).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
                }
                var lcfValueField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'listValuesLinked'");
                if(lcfValueField && lcfValueField.data){
                	lcfValueField.data.isDisabled = isDisabled;
                	if(decodeddata && decodeddata.id){
                		lcfValueField.data.options = ((decodeddata.linkedCustomField && decodeddata.linkedCustomField.listValues)?angular.copy(decodeddata.linkedCustomField.listValues):[]);
                		if(decodeddata.frontendType && decodeddata.frontendType == 'SELECT_LINKED'){
                			lcfValueField.data.listValues = ((decodeddata.listValues)?angular.copy(decodeddata.listValues):[]);
                		}
                	}
                }
                var tcfValueField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'listValuesTree'");
                if(tcfValueField && tcfValueField.data){
                	tcfValueField.data.isDisabled = isDisabled;
                	if(decodeddata && decodeddata.id){
                		if(decodeddata.frontendType && decodeddata.frontendType == 'STRUCTURAL_SELECT'){
                			tcfValueField.data.listValues = ((decodeddata.listValues)?angular.copy(decodeddata.listValues):[]);
                		}
                	}
                }
                modal.annexaFormly.model = {};
                if(decodeddata && decodeddata.id){
                	modal.extra = decodeddata;
                }
                var decodeddataAux = angular.copy(decodeddata);
                if(decodeddata && decodeddata.frontendType && (decodeddata.frontendType == 'SELECT_LINKED' || decodeddata.frontendType == 'STRUCTURAL_SELECT')){
                	decodeddataAux.listValues = [];
                }
                var gcfValueField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'customFieldsGroup'");
                if(gcfValueField && gcfValueField.data){
                	gcfValueField.data.isDisabled = isDisabled;
                	if(decodeddata && decodeddata.id){
                		if(decodeddata.frontendType && decodeddata.frontendType == 'CF_GROUP'){
                			gcfValueField.data.customFieldList = ((decodeddata.groups)?angular.copy(decodeddata.groups):[]);
                		}
                	}
                }
                modal.annexaFormly.model.modal_body = decodeddataAux;
                modal.annexaFormly.options = { formState: { readOnly: isDisabled } };
                AnnexaFormlyFactory.showModal(
                    'modalCustomField' + new Date().getTime(),
                    modal,
                    saveCustomFieldFunction,
                    false,
                    isDisabled && !_.contains(['SELECT', 'MULTIPLESELECT', 'CHECKBOX', 'RADIO', 'STRUCTURAL_SELECT', 'SELECT_LINKED', 'CF_GROUP'], decodeddata.frontendType)
                );
			}
			if(id){
				var promises = [];
	            promises.push(RestService.findOne('CustomField', id));
	            promises.push($http({method: 'GET',url: './api/custom_fields/is_disabled/'+id}));
	            promises.push(RestService.findAll('Query', 'annexaAutomatism'));
	            $q.all(promises).then(function(data) {
	                openModal(((data[0] && data[0].data)?JSOG.decode(data[0].data):{ language1:'', language2:'', language3:'', frontendType:'', backendType:'', listValues:[], active:true }), data[1].data, ((data[2] && data[2].data)?JSOG.decode(data[2].data):[]));
	            });
			}else{
				var promises = [];
	            promises.push(RestService.findAll('Query', 'annexaAutomatism'));
	            $q.all(promises).then(function(data) {
	            	openModal({ language1:'', language2:'', language3:'', frontendType:'', backendType:'', listValues:[] , active:true}, false, ((data[0] && data[0].data)?JSOG.decode(data[0].data):[]));
	            });				
			}
		}
        return factory;
    }]);