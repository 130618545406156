angular
    .module('annexaApp')
    .factory('UIButtonFactory',['$rootScope', 'Language', '$http', 'AnnexaFormlyFactory', 'RegFactory', 'globalModals', 'DialogsFactory', '$filter','$state','DccumentsFactory', 'GlobalDataFactory', function($rootScope, Language, $http, AnnexaFormlyFactory, RegFactory, globalModals, DialogsFactory, $filter, $state, DccumentsFactory, GlobalDataFactory) {
        var factory = {};
        factory.dataModelFormFields = {};

        factory.clickButton = function(id, objectId, returnType, dropdownButton) {
        	if (id == 'actions_button_dossier_view') {
            	$http({
					url: './api/plugin/uibutton/' + id + '/' + objectId,
					method: 'GET',
				}).then(function(data) {
					if(data.data){
		        		var clickButtonSubmit = function(modal) {
		        			var self = this;
		                    var dataModel = this.annexaFormly.model.modal_body;
		                    
		                    if (this.annexaFormly.model.scopeExecution) {
		                    	dataModel.scopeExecution = this.annexaFormly.model.scopeExecution;
		                    }

		                    if (this.annexaFormly.form.$valid) {
		                    	factory.dataModelFormFields = angular.copy(dataModel);
		                    	
		                    	_.forEach(factory.dataModelFormFields, function(value, index, dataModelFormFields){
		                    		if (dataModelFormFields[index] && dataModelFormFields[index] instanceof Date) {
		                        		var momentDate = moment(dataModelFormFields[index]).utc(true); //treure zona horària
		                        		dataModelFormFields[index] = momentDate.format();
		                    		} else if (dataModelFormFields[index] == null) {
		                    			dataModelFormFields[index] = '';
		                    		}
		                    	});
		                    	
		                    	// els camps que són hidden se'ls hi ha de passar el valor per defecte
		                    	_.forEach(this.annexaFormly.fields[0].fieldGroup, function(field){
		                    		if (field.type == 'annexaLabelRow') {
		                    			factory.dataModelFormFields[field.key] = field.templateOptions.value;
		                    		} else if (field.hide) {
		                    			factory.dataModelFormFields[field.key] = field.defaultValue;
		                    		}
		                    	});
		                    	
		                    	$http({
		        					url: './api/plugin/uibutton/' + id + '/click/' + objectId + '/' + dropdownButton.eClick,
		        					method: 'POST',
		        					data: factory.dataModelFormFields
		        				}).then(function(data) {
		    						modal.close();
		        					if (data.status == 200) {
		        						if(data.data){
		        							if (data.data.startsWith("http"))
		        								window.open(data.data, '_blank');
		        							else 
			        							DialogsFactory.notify(data.data, $filter('translate')('DIALOGS_NOTIFICATION'));
		        						} else {
		        							DialogsFactory.error( 'global.errors.unknownNew', $filter('translate')('DIALOGS_ERROR_MSG'));
		        						}
		        					}
		        					//refrescar
		        					$state.reload();
		        				}).catch(function(error) {
		        					console.log(error);
		        				});
		                    }
		        		}
		        		
                        _.forEach(data.data.listActionButton, function(actionButton){
                        	if (actionButton.eClick && actionButton.eClick == dropdownButton.eClick) {
                        		dropdownButton = actionButton;
                        	}
                        });
                        
		                var data = {
	                        row: true,
	                        colClass: 'col-sm-12',
	                        labelClass: 'label-strong'
	                    };

		                var showModalActionsButtonDossierView = function (scopeExecution) {
		            		var modal = {
		                        title: dropdownButton.btText,
		                        size: '',
		                        icon: '',
		                        submitModal: function () {}
		                    };
		                    modal.annexaFormly = new AnnexaFormly();
		                    modal.alerts = [];

		                    if (scopeExecution) {
		                    	modal.annexaFormly.model.scopeExecution = scopeExecution;
		                    }
		                    
		                    var formFields;
		                    var fields = [];
		                    if (dropdownButton.formFields) {
		                    	formFields = JSON.parse(dropdownButton.formFields.replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t"));
		                    }

		                    if(formFields && formFields.length){
		    	   				_.forEach(formFields, function(formField){
		    	   					var formlyField;
		    	   					
		    	   					switch (formField.type) {
		    		                    case 'text':
		    		                    case 'decimal':
		    		                    case 'number':
		    	                    		formlyField = modal.annexaFormly.createField(
		    	                    			formField.key, 'annexaInputRow',
		    		                            formField.type == 'number' || formField.type == 'decimal' ? 'col-sm-6' : 'col-sm-12',
		    		                            new AnnexaFormlyFieldTemplateOptions(
		    		                            	formField.type == 'number' || formField.type == 'decimal' ? 'number': 'text',
		    		                                formField.label,
		    		                                formField.required ? formField.required : false,
		    		                                false,
		    		                                formField.type == 'number' ? '1' : formField.type == 'decimal' ? '0.' + Array(formField.decimals).join('0') + '1' : undefined
		    		                            ),
		    		                            data, function() { return formField.hidden }, formField.type == 'number' ? parseInt(formField.value) : formField.type == 'decimal' ? parseFloat(formField.value) : formField.value);
		    		                        break;
		    		                    case 'textarea':
		    		                    	formlyField = modal.annexaFormly.createField(
		    		                    		formField.key, 'annexaTextAreaRow', 'col-sm-12',
		    		                                new AnnexaFormlyFieldTextAreaTemplateOptions(
		    		                                	formField.label,
		    		                                	formField.rows ? formField.rows : 5,
		    		                                	formField.required ? formField.required : false
		    		                                ),
		    		                                data, formField.hidden, formField.value);
		    		                    	break;
		    		                    case 'date':
		    		                    	var datepickerOptions = {
		    		                            format: 'dd/MM/yyyy',
		    		                            initDate: new Date(),
		    		                            showWeeks: false,
		    		                            startingDay: 1
		    		                        }
		    		                        var dateParts = $filter('date')(formField.value, 'dd/MM/yyyy').split("/");
		    		                        var dateObject = undefined;
		    		                        if (dateParts && dateParts.length > 1) {
		    		                        	dateObject = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]); // month is 0-based
		    		                        }

		    		                    	formlyField = modal.annexaFormly.createField(
		    		                    		formField.key, 'annexaDatepickerRow', 'col-sm-6',
		    		                            new AnnexaFormlyFieldDatepickerTemplateOptions(
		    		                                'text', formField.label,
		    		                                formField.required ? formField.required : false,
		    		                                datepickerOptions
		    		                            ),
		    		                            data, formField.hidden, dateObject);
		    		                    	break;
		    		                    case 'radio':
		    		                    case 'select':
		    		                        var optionsSelect = [];
		    		                        if (formField.options) {
		    		                        	optionsSelect = formField.options;
		    		                        } else if (formField.customOptions) {
		    		                        	optionsSelect = formField.customOptions;
		    		                        }

		    		                    	formlyField = modal.annexaFormly.createField(
		    		                    		formField.key, 
		    		                    		formField.type == 'radio' ? 'annexaRadioCheckboxRow' : 'annexaSelectRow', 
		    		                    		'col-sm-12', 
		    		                    		new AnnexaFormlyFieldSelectTemplateOptions(
		    		                    			formField.label,
		    		                    			'id', 'value',
		    		                    			optionsSelect,
		    		                    			formField.required ? formField.required : false,
		    	    		                    	formField.type == 'radio' ? formField.type : undefined
		    		                    		), 
		    		                    		data, formField.hidden, parseInt(formField.value));
		    		                    	break;
		    		                    case 'checkbox':
		    		                    	var optionsSelect = [];
		    		                        if (formField.options) {
		    		                        	optionsSelect = formField.options;
		    		                        } else if (formField.customOptions) {
		    		                        	optionsSelect = formField.customOptions;
		    		                        }
		    		                        
		    		                        var values = formField.value.split(",");
		    		                        
		    		                        formlyField = modal.annexaFormly.createField(
		    		                        	formField.key,
		    		                        	'annexaRadioCheckboxRow',
		    		                            'col-sm-12', 
		    		                            new AnnexaFormlyFieldSelectTemplateOptions(
		    		                            	formField.label,
		    		                            	'id', 'value',
		    		                            	optionsSelect,
		    		                            	formField.required ? formField.required : false,
		    	    	    		                formField.type
		    		                            ),
		    		                            data, formField.hidden, undefined);
		    		                        _.forEach(values, function(valueSelected) {
		    		                        	if (!modal.annexaFormly.model.modal_body) {
		    		                        		modal.annexaFormly.model.modal_body = {};
		    		                        	}
		    		                        	modal.annexaFormly.model.modal_body[formField.key + '_' + valueSelected] = true;
		    		                        });
		    		                        break;
		    		                    case 'multipleselect':
		    		                    	var optionsSelect = [];
		    		                        if (formField.options) {
		    		                        	optionsSelect = formField.options;
		    		                        } else if (formField.customOptions) {
		    		                        	optionsSelect = formField.customOptions;
		    		                        }
		    		                        
		    		                        var values = formField.value.split(",");
		    		                        
		    		                        var formFieldValues = $linq(optionsSelect).where(function(x){
		    		                        	if (x.id && _.contains(values, "" + x.id)) {
		    		                        		return true;
		    		                        	} else {
		    		                        		return false;
		    		                        	}
		    		                        }).toArray();
		    		                        
		    		                        formlyField = modal.annexaFormly.createField(
		    		                        	formField.key,
		    		                        	'annexaMultipleSelectRow',
		    		                            'col-sm-12', 
		    		                            new AnnexaFormlyFieldSelectTemplateOptions(
		    		                            	formField.label,
		    		                            	'id', 'value',
		    		                            	optionsSelect,
		    		                            	formField.required ? formField.required : false,
		    	    	    		                undefined
		    		                            ),
		    		                            data, formField.hidden, formFieldValues);
		    		                        break;
		    	   					}
		    	   					
		    	   					if (formField.readOnly) {
		                        		formlyField = modal.annexaFormly.createField(
		                    				formField.key, 'annexaLabelRow', 'col-sm-12',
		    	                            new AnnexaFormlyFieldLabelTemplateOptions(formField.label, formField.value),
		    	                            data, formField.hidden);
		    	   					}
		    	   					
		    		                if (formlyField) {
		    		                	// afegim la propietat defaultValue per als casos on formField.value='' 
		    		                	// ja que si no s'informa el camp al formulari i es un camp opcional, necessitem passar el valor buit
		    		                	if (formField.value == undefined || formField.value === '') formlyField.defaultValue = '';
		    		                	fields.push(formlyField);
		    		                }
		    	   				});
		       			 	}
		                    modal.annexaFormly.addGroup('modal_body','modal-body p-lg',fields);
		                    modal.annexaFormly.options = {};
		                    modal.annexaFormly.options.formState = {readOnly: false};

		            		AnnexaFormlyFactory.showModal("modalActionsButtonDossierView", modal, clickButtonSubmit, false);
		                }
		                
	                    if (dropdownButton.scopeExecution) {
	                    	var scopeExecutionJSON = JSON.parse(dropdownButton.scopeExecution.replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t"));
	                    	if (scopeExecutionJSON.key == 'box') {
	                    		showModalActionsButtonDossierView(scopeExecutionJSON);
	                    	} else {
		                    	var submitScopeExecution = function () {
		                    		var self = this;
				                    var scopeExecution = angular.copy(this.annexaFormly.model.modal_body);
				                    if (scopeExecutionJSON.key == 'box_element') {
					                    scopeExecution.type = scopeExecutionJSON.type;
				                    }
				                    scopeExecution.name = scopeExecutionJSON.name;

				                    if (this.annexaFormly.form.$valid) {
				                    	
				                    	$http({
				        					url: './api/plugin/uibutton/' + id + '/rootSpel/' + objectId + '/' + dropdownButton.eClick,
				        					method: 'POST',
				        					data: scopeExecution
				        				}).then(function(data) {
				        					if (data.status == 200) {
				        						dropdownButton.formFields = data.data;
						                    	self.close();
						                    	showModalActionsButtonDossierView(scopeExecution);
				        					}
				        				}).catch(function(error) {
				        					console.log(error);
				        				});
				                    }
		                    	}
		                    	
			            		var modalExec = {
			                        title: $filter('translate')('global.literals.scopeExecution'),
			                        size: 'modal-lg',
			                        icon: '',
			                        submitModal: function () {}
			                    };
			            		modalExec.annexaFormly = new AnnexaFormly();
			            		modalExec.alerts = [];

			            		var fieldsExec = [];
		                    	if (scopeExecutionJSON.key == 'transaction_type') {
		                    		var dtByType = angular.copy(scopeExecutionJSON.list);
		                    		var ttField = modalExec.annexaFormly.createField('transaction', 'annexaSelectRow', 'col-sm-12', new AnnexaFormlyFieldSelectTemplateOptions('global.literals.transaction','id','value',dtByType,true),data);
		                    		fieldsExec.push(ttField);
		                    	} else {
			                    	var boxElements = angular.copy(scopeExecutionJSON.list);
			                    	var boxElementTitle = 'global.enums.boxElements.' + scopeExecutionJSON.name;
				                    var field = modalExec.annexaFormly.createField('boxElement', 'annexaSelectRow', 'col-sm-12', new AnnexaFormlyFieldSelectTemplateOptions(boxElementTitle,'id','value',boxElements,true),data);
				                    fieldsExec.push(field);
		                    	}
		                    	
		                    	modalExec.annexaFormly.addGroup('modal_body','modal-body p-lg',fieldsExec);
		                    	modalExec.annexaFormly.options = {};
		                    	modalExec.annexaFormly.options.formState = {readOnly: false};

			            		AnnexaFormlyFactory.showModal("modalScopeExecutionActionsButtonDossierView", modalExec, submitScopeExecution, false);
	                    	}
	                    } else {
	                    	showModalActionsButtonDossierView();
	                    }
					}
				}).catch(function(error) {
					console.log(error);
				});
        	} else {
        		var fileContentsForDownload = function(headers, data) {
        			var retDownload = {
        					file: undefined,
        					fileName: undefined,
        					linkElement: undefined,
        					clickEvent: undefined
        			}
        			
        			var contentType = headers['content-type'];
        			retDownload.file = new Blob([data], { type: contentType });
        			retDownload.fileName = headers['content-disposition'].replace('attachment; filename="','').replace('"', '');
        			
        			try {
        				if (window.navigator.msSaveOrOpenBlob) {
        					window.navigator.msSaveOrOpenBlob(retDownload.file, retDownload.fileName);
        				} else {
        					retDownload.linkElement = document.createElement('a');
        					var url = window.URL.createObjectURL(retDownload.file);
        					retDownload.linkElement.setAttribute('href', url);
        					retDownload.linkElement.setAttribute("download", retDownload.fileName);
        					retDownload.clickEvent = new MouseEvent("click", {
        						"view": window,
        						"bubbles": true,
        						"cancelable": false
        					});
        					retDownload.linkElement.dispatchEvent(retDownload.clickEvent);
        				}
        				$rootScope.loading(false);
        			} catch(e) {
        				$rootScope.loading(false);
        			}
        		}
        		
        		var responseType = returnType == 'FILE_CONTENTS_FOR_DOWNLOAD' ? 'arraybuffer' : undefined; 
        		
        		var literalConfirm = 'global.literals.generate_dossier_ENI';
        		var docConvertiblePDF = true;
        		if (id == 'document_eni_button_document_view') {
        			literalConfirm = 'global.literals.generate_document_ENI'
        				var AnnexaDocumentActionsButtonFactory = angular.element(document.body).injector().get('AnnexaDocumentActionsButtonFactory');
        			docConvertiblePDF = AnnexaDocumentActionsButtonFactory.isDocumentConvertibleToPdf(DccumentsFactory.document, false);
        		}
        		
        		if (docConvertiblePDF) {
        			DialogsFactory.confirm('global.documents.new.audit.actionType.ENI', literalConfirm)
        			.then(function (data) {
        				$http({
        					url: './api/plugin/uibutton/' + id + '/click/' + objectId,
        					method: 'POST',
        					responseType: responseType
        				}).then(function(data) {
        					if(data.status == 406 || data.status == 500) {
        						if(data.data){
        							var dec = new TextDecoder();
        							var message = angular.fromJson(dec.decode(data.data));
        							if(message.message) {
        								var message = angular.fromJson(message.message);
        								DialogsFactory.error(message[Language.getActiveColumn()], $filter('translate')('DIALOGS_ERROR_MSG'));
        								console.log({msg: message[Language.getActiveColumn()]});
        							}else{
        								DialogsFactory.error( 'global.errors.unknownNew', $filter('translate')('DIALOGS_ERROR_MSG'));
        								console.log({msg: 'global.errors.unknownNew'});
        							}
        						} else {
        							DialogsFactory.error( 'global.errors.unknownNew', $filter('translate')('DIALOGS_ERROR_MSG'));
        							console.log({msg: 'global.errors.unknownNew'});
        						}
        					}else {
        						switch (returnType) {
        						case 'FILE_CONTENTS_FOR_DOWNLOAD':
        							fileContentsForDownload(data.headers(), data.data);
        							break;
        							
        						}
        					}
        					//refrescar
        					$state.reload();
        				}).catch(function(error) {
        					console.log(error);
        				});
        			}).catch(function(error) {
        			});
        		} else {
        			DialogsFactory.error('global.literals.generate_document_ENI_KO');
        		}
        	}
        }

        factory.updateHeaderButtons = function (state, $scope, stateParams, $location) {
        	var dataTarget = undefined;
        	var annexaState = undefined;

        	var buttons = $linq($rootScope.app.UIButtons).select(function(uiButton) {
       			var countStates = $linq(uiButton.annexaStates).count(function(uiButtonAnnexaState) {
       				if (_.contains(uiButtonAnnexaState, state.name)) {
       					annexaState = uiButtonAnnexaState[0];
       					dataTarget = uiButtonAnnexaState[1];
               			return true;
               		}	
       			});
       			
           		if (countStates > 0) {
           			return uiButton;
           		}       				
            }).toArray();
       		
            var currentURL = $location.absUrl();

            _.forEach(buttons, function (button) {
            	if (button) {
                	if (button.dropdownButtons && typeof button.dropdownButtons === 'string') {
                    	var lstDropdownButtons = angular.fromJson(button.dropdownButtons);
                		if (lstDropdownButtons.listActionButton) {
                			button.dropdownButtons = lstDropdownButtons.listActionButton;
                			button.permissions = lstDropdownButtons.permissions;
                		} else {
                			button.dropdownButtons = undefined;
                			button.permissions = undefined;
                		}
                	}
                	
                	if (button.dropdownButtons) {
                		var buttonClass = 'breadDivButton btn dropdown ml-sm-pt p-a-0 primary';
                	} else {
                		var buttonClass = 'btn primary breadDivButton ml-sm-pt';
                	}
                	
                    var headButton = new HeadButton(buttonClass, undefined, dataTarget, button.icon, button[Language.getActiveColumn()], undefined,
                        'clickButton' + button.id,undefined, undefined, button.permissions, button.dropdownButtons, undefined, button.id);
                    
                    if (button.dropdownButtons) {
                    	headButton.stateParams = stateParams;
                    	headButton.stateParams.idLoggedUser = $rootScope.LoggedUser.id;
                    	headButton.stateParams.currentURL = currentURL;
                    }

                    var position = undefined;

                    switch (button.position) {
                        case 'HEADER':
                            position = 'headButtons';
                            break;
                        case 'SUBHEADER':
                            position = 'subHeadButtons';
                            break;
                    }

                    if(position) {
                        if($linq($rootScope[position]).count("x => x.id == '" + button.id + "'") == 0) {
                            $rootScope[position].push(headButton);
                            $rootScope['clickButton' + button.id] = function() {
                                if(annexaState && stateParams.hasOwnProperty('dossier') && stateParams.dossier) {
                                    factory.clickButton(button.id, stateParams.dossier, button.returnType, null);
                                }
                            }
                            if (button.dropdownButtons) {
                            	_.forEach(button.dropdownButtons, function (dropdownButton) {
                            		if (dropdownButton.eClick) {
                            			$rootScope[dropdownButton.eClick] = function() {
                            				if(annexaState && stateParams.hasOwnProperty('dossierTransaction') && stateParams.dossierTransaction) {
                            					factory.clickButton(dropdownButton.id, stateParams.dossierTransaction, button.returnType, dropdownButton);
                            				}
                            			}
                            		}
                            	});
                            }
                        }
                    }
            	}
            });
        }

        return factory;
    }])