/**
 * Created by osirvent on 10/11/2015.
 */
'use strict';
angular.module('annexaApp')
    .factory('httpRequestMonitoring', ['$log', function($log) {
        function guid() {
            function s4() {
                return Math.floor((1 + Math.random()) * 0x10000)
                    .toString(16)
                    .substring(1);
            }
            return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
        }

        var monitor = function(url, state) {
            var $rootScope = angular.element(document.body).injector().get('$rootScope');

            if(!url) {
                url = '*'
            }

            if(url == '/log/http/monitor') {
                return false;
            }

            if(!$rootScope.app.configuration.angular_monitor_http) {
                return false
            }

            if(!$rootScope.app.configuration.angular_monitor_http.active) {
                return false;
            }

            if(!$rootScope.app.configuration.angular_monitor_http.monitor_counters && url.includes('countBy')) {
                return false;
            }

            if($rootScope.app.configuration.angular_monitor_http.allowed_states && Array.isArray($rootScope.app.configuration.angular_monitor_http.allowed_states)) {
                return _.contains($rootScope.app.configuration.angular_monitor_http.allowed_states, state);
            }

            return true;
        }

        var factory = {};

        factory.requests = [];


        factory.request = function (config) {
            var $rootScope = angular.element(document.body).injector().get('$rootScope');
            var $state = angular.element(document.body).injector().get('$state');

            if(monitor(config.url, $state.current.name)) {
                config.headers['request-uuid'] = guid();

                factory.requests.push({request: config, state: $state.current.name, start: new Date()})
            }

            return config;
        }

        factory.requestError = function (rejection) {

            return rejection;
        }

        factory.response = function(response) {
            if(response.config.headers['request-uuid']) {
                var $rootScope = angular.element(document.body).injector().get('$rootScope');

                var end = new Date();

                var indexRequest = $linq(factory.requests).indexOf("x => x.request.headers['request-uuid'] == '" + response.config.headers['request-uuid'] + "'");

                if(indexRequest != -1) {
                    factory.requests[indexRequest].end = end;
                    factory.requests[indexRequest].durationMili = factory.requests[indexRequest].end.getTime() - factory.requests[indexRequest].start.getTime();
                    factory.requests[indexRequest].durationSec = factory.requests[indexRequest].durationMili / 1000;

                    var $http = angular.element(document.body).injector().get('$http');

                    var monitorHttp = {
                        url: factory.requests[indexRequest].request.url,
                        params: response.config.params ? JSON.stringify(response.config.params) : undefined,
                        state: factory.requests[indexRequest].state,
                        start: factory.requests[indexRequest].start,
                        end: factory.requests[indexRequest].end,
                        milliseconds: factory.requests[indexRequest].durationMili,
                        seconds: factory.requests[indexRequest].durationSec,
                        user: $rootScope.LoggedUser ? { id: $rootScope.LoggedUser.id } : undefined,
                        browser: bowser.name + ' ' + bowser.version,
                        groupName: $rootScope.app.configuration.angular_monitor_http.group ? $rootScope.app.configuration.angular_monitor_http.group : undefined
                    }

                    $http({
                        url: '/log/http/monitor',
                        method: 'POST',
                        data: monitorHttp
                    }).then(function(data) {
                        var decodedData = JSOG.decode(data);

                        $log.debug('[Annexa http Monitoring]\n ' +
                            'URL -> ' + decodedData.url + '\n' +
                            'Params -> ' + decodedData.params + '\n' +
                            'State -> ' + decodedData.state + '\n' +
                            'Mili -> ' + factory.requests[indexRequest].durationMili + '\n' +
                            'Sec -> ' + factory.requests[indexRequest].durationSec
                        );

                    });
                }
            }

            return response;
        }

        factory.responseError = function (rejection) {

            return rejection;
        }

        return factory;
    }])
    .factory('httpRequestInterceptor', ['$rootScope', '$injector', '$q', function($rootScope, $injector, $q)
    {
        return {
            request: function($config) {
                if($rootScope.LoggedUser){
                	if ($rootScope.LoggedUser.tokenAuthenticateWithPlugin) {
                    	$config.headers['x-auth-token'] = $rootScope.LoggedUser.tokenAuthenticateWithPlugin;
                    } else if( $rootScope.LoggedUser.isAuthenticated ) {
                        $config.headers['x-auth-token'] = $rootScope.LoggedUser.token;
                        /***/
                        $config.headers['x-tenant-ID'] = $rootScope.LoggedUser.tenant;
                        /***/
                    }
                }
                if($config.url && $config.url.indexOf('/api') != -1 && $config.url.indexOf('/api/tasks/pending') == -1 && $config.url.indexOf('/api/alerts/pending') == -1 && $config.url.indexOf('/api/signprocessactions/pending') == -1){
	                if(!$config.url.endsWith('/api/sign/do') && !$config.url.endsWith('/api/sign/doSignCloudSignAndorra') && $config.url.indexOf('/api/sign/statusSignProcess') == -1 &&
	                	!$config.url.endsWith('/api/doc/PENDING_SIGN/status/count') &&	!$config.url.endsWith('/api/tram/counters/PendingTramitation') && !$config.url.endsWith('/api/tram/counters/Expired') &&
						!$config.url.endsWith('/api/tram/counters/PendingSee') && !$config.url.endsWith('/api/tram/counters/Finally') && !$config.url.endsWith('/api/tram/counters/Favorite') &&
						!$config.url.endsWith('/api/tram/counters/Participated') && $config.url.indexOf('/api/reg/dashboard/counters') == -1 && $config.url.indexOf('/api/tasks/dashboard/counters/full') == -1 &&
						$config.url.indexOf('/api/signprocessactions/countPending/') == -1 && !$config.url.endsWith('/api/signprocessactions/countPending/urgent') && 
						$config.url.indexOf('/api/delegationUsers/findByDelegatorUserIdAndEndDelegationDateAfter') == -1 && $config.url.indexOf('/api/delegationUsers/findByDelegatedUserIdAndStartDelegationDateBeforeAndEndDelegationDateAfter') == -1 &&
						$config.url.indexOf('/getNextRegisterEntry/') == -1 && $config.url.indexOf('/getPreviousRegisterEntry/') == -1 &&
						$config.url.indexOf('/api/general/filterById') == -1 && $config.url.indexOf('/api/tram/new/dossier/counters/') == -1 && $config.url.indexOf('/api/sec/session/dashboard/counters/') == -1 && 
						$config.url.indexOf('/api/sec/proposal/dashboard/counters/') == -1 && $config.url.indexOf('/api/sec/decree/dashboard/counters/') == -1 && $config.url.indexOf('/api/general/count') == -1 &&
						$config.url.indexOf('/api/sec/session/satusSessionGenerateCertificates/') == -1 && $config.url.indexOf('/api/tram/new/dossierTransaction/calculateDossierTransactionDocumentsCounters/') == -1){ 
	                	if(!$config.headers.noLoading) {
		                    $rootScope.loading(true);
		                }
	                }
                }
                
                //Timeout global de angular (tambien tener el cuenta el timeout de apache en "/etc/httpd/conf/virtual" si no esta definido "Timeout" en el fichero ".conf" por defecto son 60 segundos)
                if ($rootScope.app.configuration.angular_timeout_in_seconds && 
                		$rootScope.app.configuration.angular_timeout_in_seconds.value > 0) {
                	$config.timeout = $rootScope.app.configuration.angular_timeout_in_seconds.value * 1000; //en milisegundos
                }                
                
                return $config;
            },
            response: function(response) {
            	if(response.config && response.config.url && response.config.url.indexOf('/api') != -1 && response.config.url.indexOf('/api/tasks/pending') == -1 && response.config.url.indexOf('/api/signprocessactions/pending') == -1 && response.config.url.indexOf('/api/alerts/pending') == -1){
                    if(response.config != null || response.config.headers != null || response.config.headers.noLoading != null) {
                    	if(!response.config.url.endsWith('/api/sign/do') && !response.config.url.endsWith('/api/sign/doSignCloudSignAndorra') && response.config.url.indexOf('/api/sign/statusSignProcess') == -1 &&
  	                	!response.config.url.endsWith('/api/doc/PENDING_SIGN/status/count') &&	!response.config.url.endsWith('/api/tram/counters/PendingTramitation') && !response.config.url.endsWith('/api/tram/counters/Expired') &&
   						!response.config.url.endsWith('/api/tram/counters/PendingSee') && !response.config.url.endsWith('/api/tram/counters/Finally') && !response.config.url.endsWith('/api/tram/counters/Favorite') &&
   						!response.config.url.endsWith('/api/tram/counters/Participated') && response.config.url.indexOf('/api/reg/dashboard/counters') == -1 && response.config.url.indexOf('/api/tasks/dashboard/counters/full') == -1 &&
   						response.config.url.indexOf('/api/signprocessactions/countPending/') == -1 && !response.config.url.endsWith('/api/signprocessactions/countPending/urgent') && 
						response.config.url.indexOf('/api/delegationUsers/findByDelegatorUserIdAndEndDelegationDateAfter') == -1 && response.config.url.indexOf('/api/delegationUsers/findByDelegatedUserIdAndStartDelegationDateBeforeAndEndDelegationDateAfter') == -1 &&
						response.config.url.indexOf('/getNextRegisterEntry/') == -1 && response.config.url.indexOf('/getPreviousRegisterEntry/') == -1 &&
						response.config.url.indexOf('/api/general/filterById') == -1 && response.config.url.indexOf('/api/tram/new/dossier/counters/') == -1 && response.config.url.indexOf('/api/sec/session/dashboard/counters/') == -1 && 
						response.config.url.indexOf('/api/sec/proposal/dashboard/counters/') == -1 && response.config.url.indexOf('/api/sec/decree/dashboard/counters/') == -1 && response.config.url.indexOf('/api/general/count') == -1 &&
						response.config.url.indexOf('/api/sec/session/satusSessionGenerateCertificates/') == -1 && response.config.url.indexOf('/api/tram/new/dossierTransaction/calculateDossierTransactionDocumentsCounters/') == -1){
        	                $rootScope.loading(false);
                    	}
	                }
            	}
                if(response.status == 403){
                    $injector.get('LoginService').LogOut();
                    return $q.reject(response);
                }
                return response;
            },
            responseError: function(errorResponse) {
            	if(errorResponse.config && errorResponse.config.url && errorResponse.config.url.indexOf('/api') != -1 && errorResponse.config.url.indexOf('/api/tasks/pending') == -1 && errorResponse.config.url.indexOf('/api/signprocessactions/pending') == -1 && errorResponse.config.url.indexOf('/api/alerts/pending') == -1){
                    if(errorResponse && errorResponse.config && errorResponse.config.headers && !errorResponse.config.headers.noLoading) {
                    	if(!errorResponse.config.url.endsWith('/api/sign/do') && !errorResponse.config.url.endsWith('/api/sign/doSignCloudSignAndorra') && errorResponse.config.url.indexOf('/api/sign/statusSignProcess') == -1 &&
        	                	!errorResponse.config.url.endsWith('/api/doc/PENDING_SIGN/status/count') &&	!errorResponse.config.url.endsWith('/api/tram/counters/PendingTramitation') && !errorResponse.config.url.endsWith('/api/tram/counters/Expired') &&
        						!errorResponse.config.url.endsWith('/api/tram/counters/PendingSee') && !errorResponse.config.url.endsWith('/api/tram/counters/Finally') && !errorResponse.config.url.endsWith('/api/tram/counters/Favorite') &&
        						!errorResponse.config.url.endsWith('/api/tram/counters/Participated') && errorResponse.config.url.indexOf('/api/reg/dashboard/counters') == -1 && errorResponse.config.url.indexOf('/api/tasks/dashboard/counters/full') == -1 &&
        						errorResponse.config.url.indexOf('/api/signprocessactions/countPending/') == -1 && !errorResponse.config.url.endsWith('/api/signprocessactions/countPending/urgent') && 
        						errorResponse.config.url.indexOf('/api/delegationUsers/findByDelegatorUserIdAndEndDelegationDateAfter') == -1 && errorResponse.config.url.indexOf('/api/delegationUsers/findByDelegatedUserIdAndStartDelegationDateBeforeAndEndDelegationDateAfter') == -1 &&
								errorResponse.config.url.indexOf('/getNextRegisterEntry/') == -1 && errorResponse.config.url.indexOf('/getPreviousRegisterEntry/') == -1 &&
								errorResponse.config.url.indexOf('/api/general/filterById') == -1 && errorResponse.config.url.indexOf('/api/tram/new/dossier/counters/') == -1 && errorResponse.config.url.indexOf('/api/sec/session/dashboard/counters/') == -1 && 
								errorResponse.config.url.indexOf('/api/sec/proposal/dashboard/counters/') == -1 && errorResponse.config.url.indexOf('/api/sec/decree/dashboard/counters/') == -1 && errorResponse.config.url.indexOf('/api/general/count') == -1 &&
								errorResponse.config.url.indexOf('/api/sec/session/satusSessionGenerateCertificates/') == -1 && errorResponse.config.url.indexOf('/api/tram/new/dossierTransaction/calculateDossierTransactionDocumentsCounters/') == -1){
        	                $rootScope.loading(false);
                    	}
                    }
                }
                if(errorResponse.status == 403){
                    $injector.get('LoginService').LogOut();
                    return $q.reject(errorResponse);
                } else {
                    return $q.reject(errorResponse);
                }
                return errorResponse;
            }
        };
    }]);