/**
 * Created by osirvent on 16/11/2016.
 */
angular.module('annexaApp')
    .directive('annexaPermission',['AnnexaPermissionsFactory', function(AnnexaPermissionsFactory) {
        return {
            restrict: 'A',
            scope: {
                permissionOnly: '=annexaPermissionOnly',
                permissionOnlyProfile: '=annexaPermissionOnlyProfile'
            },
            link: function (scope, element, attr) {
                if(scope.permissionOnly) {
                    var permissions = [];

                    if(!Array.isArray(scope.permissionOnly)) {
                        permissions = [scope.permissionOnly];
                    } else {
                        permissions = scope.permissionOnly;
                    }

                    var have = false;

                    angular.forEach(permissions, function (permission) {
                        //MULTIPLE_PROFILES
                        if(scope.permissionOnlyProfile) {
                            if(!Array.isArray(scope.permissionOnlyProfile) && scope.permissionOnlyProfile.id) {
                                have = have || AnnexaPermissionsFactory.haveSinglePermissionDual(permission, scope.permissionOnlyProfile.id);
                            } else {
                                have = have || AnnexaPermissionsFactory.haveSinglePermissionDual(permission, scope.permissionOnlyProfile);
                            }
                        }else{
                            have = have || AnnexaPermissionsFactory.haveSinglePermissionDual(permission);
                        }
                        //MULTIPLE_PROFILES
                    })

                    if(!have) {
                        element.hide();
                    }
                }
            }
        }
    }])
    .directive('hrefPermission',['$compile','AnnexaPermissionsFactory', 'CommonService', '$rootScope', 'DialogsFactory', function($compile, AnnexaPermissionsFactory, CommonService, $rootScope, DialogsFactory) {

        var getHref = function(classes, name, icon, state, parameter, parameter_value, parameter_type) {
            var template = '';

            var sref = state + '({ ';

            var getParameterString = function(param, value, type) {
                var paramString = '';

                paramString += param + ': ';

                if(type == 'String') {
                    paramString += '\'' + value + '\'';
                } else {
                    paramString += value;
                }

                return paramString;
            };

            if(!Array.isArray(parameter)) {
                sref += getParameterString(parameter, parameter_value, parameter_type);
            } else {
                if(Array.isArray(parameter_value)) {
                    var paramters = '';

                    angular.forEach(parameter, function (value, index) {
                        if (parameter_value[index]) {
                            if (paramters != '') {
                                paramters += ', ';
                            }
                            var param_type = undefined;

                            if(Array.isArray(parameter_type) && parameter_type[index]) {
                                param_type = parameter_type[index];
                            }

                            paramters += getParameterString(value, parameter_value[index], param_type);
                        }
                    });

                    if(paramters != '') {
                        sref += paramters;
                    } else {
                        sref += getParameterString('error', '1');
                    }
                } else {
                    sref += getParameterString('error', '1');
                }
            }

            sref += ' })';

            template += '<a class="' + classes + '" href="" ui-sref="' + sref + '" target="_blank">' + ((name)?name:'') +' '+((icon)?icon:'') + '</a>';

            return template;
        };

        var getSpan = function (text, icon) {
            return '<span>' + ((text)?text:'') +' '+((icon)?icon:'') + '</span>'
        };

        var have = function(permissions) {
            var have = false;
            angular.forEach(permissions, function (permission) {
                have = have || AnnexaPermissionsFactory.haveSinglePermissionDual(permission);
            });

            return have;
        };

        var getHtml = function (scope, permissions) {
            if (!have(permissions)) {
                return getSpan(scope.nameHref, scope.iconHref);
            } else {
                return getHref(scope.classHref, scope.nameHref, scope.iconHref, scope.linkHref, scope.attrCompare, scope.idHref, scope.attrType);
            }
        };

        return {
            restrict: 'E',
            replace: true,
            scope: {
                hrefPermissionOnly: '=',
                nameHref: '=',
                idHref: '=',
                linkHref: '@',
                classHref: '@',
                attrCompare: '@',
                attrType: '@',
                objectType: '@',
                object: '=',
                iconHref:'@'
            },
            link: function (scope, element, attr) {
                if(scope.hrefPermissionOnly){
                    var permissions = CommonService.convertToArray(scope.hrefPermissionOnly);

                    element.html(getHtml(scope, permissions)).show();
                } else {
                    switch (scope.objectType) {
                        case "DOSSIER_TRANSACTION":
                            var viewProfiles = $linq(scope.object.dossier.procedure.procedureViewProfiles).selectMany("x => x.profile", "(p, obj) => p").toArray();
                            var processProfiles = $linq(scope.object.processProfiles).selectMany("x => x.profile", "(p, obj) => p").toArray();
                            var haveProcessProfile = false;
                            angular.forEach(processProfiles, function (profile) {
                                if(AnnexaPermissionsFactory.haveSinglePermissionDual('process_dossier', profile.id)) {
                                    haveProcessProfile = true;
                                }
                            });
                            if(scope.object.dossier.dossierStatus != 'ARCHIVED' && scope.object.dossier.dossierStatus != 'REJECTED' && scope.object.dossier.dossierStatus != 'TRANSFER'){
	                            if(haveProcessProfile && scope.object.actual) {
	                                element.html(getHref(scope.classHref, scope.nameHref, scope.iconHref, 'annexa.tram.pending.viewdossier', ['dossier', 'dossierTransaction'], [scope.object.dossier.id, scope.object.id])).show();
	                            } else if(haveProcessProfile && !scope.object.actual) {
	                                element.html(getHref(scope.classHref, scope.nameHref, scope.iconHref, 'annexa.tram.view.viewdossier', ['dossier', 'dossierTransaction'], [scope.object.dossier.id, scope.object.id]));
	                            } else if(!haveProcessProfile) {
	                                var haveViewProfile = false;
	                                angular.forEach(viewProfiles, function (profile) {
	                                    if(AnnexaPermissionsFactory.haveSinglePermissionDual('view_dossier', profile.id) || AnnexaPermissionsFactory.haveSinglePermissionDual('see_all_dossier', profile.id)) {
	                                        haveViewProfile = true;
	                                    }
	                                });
	                                if(haveViewProfile) {
	                                    element.html(getHref(scope.classHref, scope.nameHref, scope.iconHref, 'annexa.tram.view.viewdossier', ['dossier', 'dossierTransaction'], [scope.object.dossier.id, scope.object.id]));
	                                } else {
	                                    element.html(getSpan(scope.nameHref, scope.iconHref)).show();
	                                }
	                            }
                            }else{
                            	 element.html(getSpan(scope.nameHref, scope.iconHref)).show();
                            }
                            break;
                        case "DOSSIER":
                            var viewProfiles = $linq(scope.object.procedure.procedureViewProfiles).selectMany("x => x.profile", "(p, obj) => p").toArray();
                            var haveViewProfile = false;
                            angular.forEach(viewProfiles, function (profile) {
                                if(AnnexaPermissionsFactory.haveSinglePermissionDual('view_dossier', profile.id) || AnnexaPermissionsFactory.haveSinglePermissionDual('see_all_dossier', profile.id)) {
                                    haveViewProfile = true;
                                }
                            });
                            if(scope.object.dossierStatus != 'ARCHIVED' && scope.object.dossierStatus != 'REJECTED' && scope.object.dossierStatus != 'TRANSFER'){
	                            if(haveViewProfile) {
	                                element.html(getHref(scope.classHref, scope.nameHref, scope.iconHref, 'annexa.tram.view.viewdossier', ['dossier', 'dossierTransaction'], [scope.object.id, -1]));
	                            } else {
	                                var actualHaveProcess = false;
	                                var dt = undefined;
	                                var found = false;
	                                angular.forEach(scope.object.dossierTransactions, function (dossierTransaction) {
	                                    if(!found) {
	                                        var processProfiles = $linq(dossierTransaction.processProfiles).selectMany("x => x.profile", "(p, obj) => p").toArray();
	
	                                        angular.forEach(processProfiles, function (profile) {
	                                            if (AnnexaPermissionsFactory.haveSinglePermissionDual('process_dossier', profile.id)) {
	                                                if(dossierTransaction.actual) {
	                                                    actualHaveProcess = true;
	                                                }
	                                                dt = dossierTransaction;
	                                            }
	                                        });
	
	                                        if(actualHaveProcess) {
	                                            found = true;
	                                        }
	                                    }
	                                });
	
	                                if(actualHaveProcess) {
	                                    element.html(getHref(scope.classHref, scope.nameHref, scope.iconHref, 'annexa.tram.pending.viewdossier', ['dossier', 'dossierTransaction'], [scope.object.id, -1])).show();
	                                } else {
	                                    if(dt) {
	                                        element.html(getHref(scope.classHref, scope.nameHref, scope.iconHref, 'annexa.tram.view.viewdossier', ['dossier', 'dossierTransaction'], [scope.object.id, dt.id]));
	                                    } else {
	                                        element.html(getSpan(scope.nameHref, scope.iconHref)).show();
	                                    }
	                                }
	                            }
                            }else{
                            	element.html(getSpan(scope.nameHref, scope.iconHref)).show();
                            }
                            break;
                        case "DOCUMENT":
                        	if(scope.object && scope.object.docStatus && scope.object.docStatus == 'ARCHIVED'){
                        	    element.html(getSpan(scope.nameHref, scope.iconHref)).show();
                        	}else{
	                            //MULTIPLE_PROFILES
	                            if(scope.object && scope.object.profiles && Array.isArray(scope.object.profiles)) {
	                            //MULTIPLE_PROFILES
	                                var havePermission = false;
	                                //MULTIPLE_PROFILES
	                                if (AnnexaPermissionsFactory.haveSinglePermissionDual('view_documents', scope.object.profiles)) {
	                                //MULTIPLE_PROFILES
	                                    element.html(getHref(scope.classHref, scope.nameHref, scope.iconHref, 'annexa.doc.documents.view', 'code', scope.object.id, 'String'));
	                                } else {
	                                    element.html(getSpan(scope.nameHref, scope.iconHref)).show();
	                                }
	                            }else{
	                                element.html(getSpan(scope.nameHref, scope.iconHref)).show();
	                            }
                        	}
                            break;
                        default:
                            element.html(getSpan(scope.nameHref, scope.iconHref)).show();
                            break
                    }
                }

                $compile(element.contents())(scope);
            }
        }
    }]);