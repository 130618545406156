/**
 * Created by osirvent on 01/08/2016.
 */
angular.module('annexaApp')
    .directive('annexaPresetSignActions',['$compile', '$filter', 'Language','RestService', 'SignModals', 'AnnexaFormlyFactory', 'GlobalDataFactory', 'HelperService', 'AdminFactory', function ($compile, $filter, Language, RestService, SignModals, AnnexaFormlyFactory, GlobalDataFactory, HelperService, AdminFactory) {
        var getTemplate = function(scope) {
            var template = '';
            template += '<div class="row scroll-x">';
            template += '   <table class="table m-a-0 table-striped">';
            template += '       <thead>';
            template += '           <tr>';
            template += '               <th style="width:17%"><span translate="global.literals.order">Order</span></th>';
            template += '               <th style="width:25%"><span translate="global.literals.type">Type</span></th>';
            template += '               <th style="width:25%"><span translate="global.literals.user_level">User / Level</span></th>';
            template += '               <th style="width:25%"><span translate="global.literals.action">Action</span></th>';
            template += '               <th style="width:17%"><span translate="global.literals.days">Days</span></th>';
            template += '               <th style="width:16%"></th>';
            template += '           </tr>';
            template += '       </thead>';
            template += '       <tr ng-repeat="action in presetSignActionDefinition.presetSignActions | orderBy : \'actionOrder\'">';
            template += '           <td class="v-middle">';
            template += '                   {{ action.actionOrder || circuit.empty}}';
            template += '           </td>';
            template += '           <td class="v-middle">';
            template += '                   <span ng-if="action.signActionType.code != \'THIRDSIGN\' && action.signActionType.code != \'AUTOSIGN_ORGAN\' && action.signActionType.code != \'PARALELCONDITIONEDSIGN\'">{{ action.signatoryType[presetSignActionDefinition.languageColumn]}}</span>';
            template += '                   <span ng-if="action.signActionType.code == \'THIRDSIGN\'" translate="global.documents.modal.sendToSign.third">Third</span>';
            template += '                   <span ng-if="action.signActionType.code == \'AUTOSIGN_ORGAN\'">{{ getAutosignType(action.diligenceStampType) }}</span>';
            template += '                   <span ng-if="action.paralelConditionedAction" translate="global.documents.modal.sendToSign.paralelConditionedSign">Paralel Sign</span>';
            template += '           </td>';
            template += '           <td class="v-middle" ng-show="showUsers(action)">'
            template += '                   {{ getUserName(action.user)}}';
            template += '           </td>';
            template += '           <td class="v-middle" ng-show="showLevel(action)">';
            template += '                   {{ action.actionLevel || circuit.empty}}';
            template += '           </td>';
            template += '           <td class="v-middle" ng-show="showThird(action)">';
            template += '                   {{ getThirdName(action) }}';
            template += '           </td>';
            template += '           <td class="v-middle" ng-show="action.paralelConditionedAction">';
            template += '           		{{ circuit.empty }}';
            template += '           </td>';
            template += '           <td class="v-middle" ng-show="!action.paralelConditionedAction">';
            template += '                   {{ action.signActionType[presetSignActionDefinition.languageColumn] }}';
            template += '           </td>';
            template += '           <td class="v-middle" ng-show="action.paralelConditionedAction">';
            template += '           		{{ circuit.empty }}';
            template += '           </td>';
            template += '           <td class="v-middle">';
            template += '                   {{ action.maxDurationDays || circuit.empty }}';
            template += '           </td>';
            template += '           <td class="text-nowrap">';
            template += '               <div class="pull-right">';
            template += '                   <div class="buttons" ng-show="!rowform.$visible">';
            template += '                       <button class="btn btn-sm no-bg" ng-click="editAction($index)"><i class="fa fa-pencil grey-pencil" title="{{ \'global.literals.edit\' | translate}}"></i></button>';
            template += '                       <button class="btn btn-sm no-bg " ng-show="presetSignActionDefinition.presetSignActions.length > 1" ng-click="removeAction($index)"><i class="fa  fa-remove grey-pencil" aria-hidden="true"></i><span class="sr-only">{{ \'global.literals.remove\' | translate }}</span></button>';
            template += '                   </div>';
            template += '               </div>';
            template += '           </td>';
            template += '       </tr>';
            template += '    </table>';
            template += '    <div class="dker p-a b-t">';
            template += '           <div class="row">';
            template += '               <button class="btn btn-sm primary m-l" ng-click="addAction()"><i class="fa fa-plus"></i> <span translate="global.literals.addAction">Add Action</span></button>';
            template += '           </div>';
            template += '    </div>';
            template += '</div>';
            return template;
        };

        return {
            restrict: 'E',
            replace: true,
            scope:{
                presetSignActionDefinition: '='
            },
            transclude: true,
            link: function(scope, element, attr, ctrl, transclude) {
                scope.users = [];
                scope.showUsers = function(action) {
                    var $rootScope = angular.element(document.body).injector().get('$rootScope');
                    if(!action.paralelConditionedAction && action.signActionType.code != 'THIRDSIGN') {
                    	if(action.signatoryType) {
                            if (action.signatoryType.id == $rootScope.app.configuration.signatory_type_user.value) {
                                return true
                            } else {
                                return false
                            }
                        } else {
                            return false;
                        }
                    } else {
                    	return false;
                    }
                };

                scope.showLevel = function(action) {
                    var $rootScope = angular.element(document.body).injector().get('$rootScope');
                    if(!action.paralelConditionedAction && action.signActionType.code != 'THIRDSIGN') {
                    	if(action.signatoryType) {
                            if (action.signatoryType.id == $rootScope.app.configuration.signatory_type_user.value) {
                                return false
                            } else {
                                return true
                            }
                        } else {
                            return true;
                        }
                    } else {
                    	return false;
                    }
                };
                
                scope.showThird = function(action) {
                    if(!action.paralelConditionedAction && action.signActionType.code == 'THIRDSIGN') {
                    	return true;
                    } else {
                    	return false;
                    }
                };
                
                scope.showParalelSign = function(action) {
                	if(action.paralelConditionedAction) {
                    	return true;
                    } else {
                    	return false;
                    }
                }

                scope.checkName = function(data){
                    if (!data) {
                        return $filter('translate')('global.validation.required');
                    }
                }
                scope.changeType = function (data, index) {
                    scope.presetSignActionDefinition.presetSignActions[index].signatoryType = data;
                    scope.presetSignActionDefinition.presetSignActions[index].actionLevel = undefined;
                    scope.presetSignActionDefinition.presetSignActions[index].user  = undefined;
                };
                scope.loadUsers = function(data){
                    return RestService.loadData('common','User',data.val).then(function (data) {
                        var response = [];
                        angular.forEach(JSOG.decode(data.data).content, function(value, key) {
                            var name = value.name;
                            if(value.surename1){ name = name + " "+value.surename1};
                            if(value.surename2){name = name + " "+value.surename2};
                            response.push({ id: value.id, object: {id:value.id, name: value.name, surename1:value.surename1, surename2:value.surename2}, value: name });
                        });
                        return response;
                    });
                };
                scope.addAction = function (){
                	var modal = angular.copy(SignModals.addCustomCircuit);
                	modal.annexaFormly.model = {};
                    modal.annexaFormly.model.modal_body = { maxDurationDays: 5, chkSignatoryType: true, actionOrder: scope.presetSignActionDefinition.presetSignActions.length + 1, signatureWithoutOrder: scope.signatureWithoutOrder};
                    var signActionTypeField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'signActionType'");
                    var signatoryTypeField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'signatoryType'");
                    var actionOrderField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'actionOrder'");
                    var diliStampTypeField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'diligenceStampType'");
                    var conditionedActionListField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'conditionedActionList'");
                    if(signatoryTypeField) {
                    	_.forEach(scope.presetSignActionDefinition.signatoryTypes, function (value) {
               				var type = value;
               				type.label = value[Language.getActiveColumn()];
               				signatoryTypeField.templateOptions.options.push(type);
               			});
                    }
                    if(signActionTypeField) {
                    	signActionTypeField.templateOptions.label = 'global.literals.action';
                    }
                    if(actionOrderField) {
                    	actionOrderField.templateOptions.maxNumber = scope.presetSignActionDefinition.presetSignActions.length + 1;
                    }
                    if(diliStampTypeField) {
                    	_.forEach(GlobalDataFactory.diligenceStampTypes, function (value) {
                    		if(value.certificateType == 'ORGAN') {
                    			value.label = value[Language.getActiveColumn()];
                   				diliStampTypeField.templateOptions.options.push(value);
                    		}
               			});
                    }
                    if(conditionedActionListField) {
                    	conditionedActionListField.data.actionsList = [];
                    	conditionedActionListField.data.chkSignatoryType = true;
                    	conditionedActionListField.data.selectReceiverList = [];
                    }
                	var add = function() {
                		var valid = true;
                		var model = this.annexaFormly.model.modal_body;
                		if(model && model.signActionType && model.signActionType.code == 'THIRDSIGN') {
                			model.user = { user: undefined };
                			model.actionLevel = undefined;
                			model.signatoryType = undefined;
                			model.paralelSignAction = undefined;
                			model.onlyOneSign = false;
                		} else if(model && model.signActionType && model.signActionType.code == 'AUTOSIGN_ORGAN') {
                			model.user = { user: undefined };
                			model.actionLevel = undefined;
                			model.signatoryType = undefined;
                			model.third = { third: undefined };
                			model.thirdAddress = undefined;
                			model.paralelSignAction = undefined;
                			model.onlyOneSign = false;
                		} else if(model && model.paralelConditionedAction) {
                			if(this.annexaFormly.fields && this.annexaFormly.fields[0] && this.annexaFormly.fields[0].fieldGroup) {
                				var conditionedActionListField = $linq(this.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'conditionedActionList'");
                				if(conditionedActionListField && conditionedActionListField.data && conditionedActionListField.data.actionsList) {
                					if(conditionedActionListField.data.actionsList.length > 0) {
                            			model.user = { user: undefined };
                            			model.actionLevel = undefined;
                            			model.signatoryType = undefined;
                            			model.third = { third: undefined };
                            			model.thirdAddress = undefined;
                            			model.diligenceStampType = undefined;
                            			model.subject = undefined;
                            			model.subjectAux = undefined;
                            			model.paralelSignAction = conditionedActionListField.data.actionsList;
                					} else {
                						valid = false;
                						this.alerts.push({ msg: 'global.documents.modal.sendToSign.errorConditionedSignNoAction' });
                					}
                				}
                			}
                		} else {
                			model.third = { third: undefined };
                			model.thirdAddress = undefined;
                			model.paralelSignAction = undefined;
                			model.onlyOneSign = false;
                			var $rootScope = angular.element(document.body).injector().get('$rootScope');
                			if (model.signatoryType.id == $rootScope.app.configuration.signatory_type_user.value) {
                    			model.actionLevel = undefined;
                            } else if (model.signatoryType.id != $rootScope.app.configuration.signatory_type_user.value) {
                    			model.user = { user: undefined };
                            }
                		}
                		if(valid) {
                			var index = scope.presetSignActionDefinition.presetSignActions.push({
                    			paralelConditionedAction: model.paralelConditionedAction,
                                actionOrder: model.actionOrder,
                                signActionType: model.signActionType,
                                signatoryType: model.signatoryType,
                                actionLevel: model.actionLevel,
                                user: model.user.user,
                                third: model.third.third,
                                thirdAddress: model.thirdAddress,
                                maxDurationDays: model.maxDurationDays,
                                diligenceStampType: model.diligenceStampType,
                                subject: model.subject,
                                subjectAux: model.subjectAux,
                                paralelSignAction: model.paralelSignAction,
                                onlyOneSign: model.onlyOneSign
                            }) -1;
                    		scope.chkAndUpdateActionOrder(model.actionOrder, index);
                    		if (scope.presetSignActionDefinition.saveActionFunction){
                    			scope.presetSignActionDefinition.saveActionFunction();
                    		}
                    		this.close();
                		}
                	};
                	AnnexaFormlyFactory.showModal("modalAddPresetSignCircuit", modal, add, false);
                }
                scope.editAction = function ($index){
                	var modal = angular.copy(SignModals.addCustomCircuit);
                    modal.title = 'global.documents.modal.sendToSign.editUserThird';
                	modal.annexaFormly.model = {};
                    angular.forEach(scope.presetSignActionDefinition.presetSignActions[$index].paralelSignAction,function(value, key){
                    	if(value.signActionType && value.signActionType.code != 'THIRDSIGN' && value.signActionType.code != 'AUTOSIGN_ORGAN') {
                    		var aux = HelperService.findFirstElementWhere(AdminFactory.signatoryTypes, 'id', value.signatoryType.id, -1);
                            if(aux != -1){
                                value.signatoryType = aux;
                            }
                            var aux2 = HelperService.findFirstElementWhere(AdminFactory.signActionTypes, 'id', value.signActionType.id, -1);
                            if(aux != -1){
                                value.signActionType = aux2;
                            }
                    	}
                        if(value.user){
                            var name = value.user.name;
                            if(value.user.surename1){ name = name + " "+value.user.surename1};
                            if(value.user.surename2){name = name + " "+value.user.surename2};
                            value.userSelect = { id: value.user.id, object: {id:value.user.id, name: value.user.name, surename1:value.user.surename1, surename2:value.user.surename2}, value: name };
                        }
                    });
                    var signActionTypeField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'signActionType'");
                    var signatoryTypeField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'signatoryType'");
                    var actionOrderField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'actionOrder'");
                    var diliStampTypeField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'diligenceStampType'");
                    var conditionedActionListField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'conditionedActionList'");
                    if(signatoryTypeField) {
                    	_.forEach(scope.presetSignActionDefinition.signatoryTypes, function (value) {
               				value.label = value[Language.getActiveColumn()];
               				signatoryTypeField.templateOptions.options.push(value);
               			});
                    }
                    if(signActionTypeField) {
                    	signActionTypeField.templateOptions.label = 'global.literals.action';
                    }
                    if(actionOrderField) {
                    	actionOrderField.templateOptions.maxNumber = scope.presetSignActionDefinition.presetSignActions.length;
                    }
                    if(diliStampTypeField) {
                        _.forEach(GlobalDataFactory.diligenceStampTypes, function (value) {
	                            if(value.certificateType == 'ORGAN') {
	                            	value.label = value[Language.getActiveColumn()];
	                                   diliStampTypeField.templateOptions.options.push(value);
	                                   if(scope.presetSignActionDefinition.presetSignActions[$index].diligenceStampType 
	                                		   && scope.presetSignActionDefinition.presetSignActions[$index].diligenceStampType.id == value.id) {
	                                	   scope.presetSignActionDefinition.presetSignActions[$index].diligenceStampType = value;
	                                   }
	                            }
                           });
                    }
                    if(conditionedActionListField) {
                    	conditionedActionListField.data.actionsList = scope.presetSignActionDefinition.presetSignActions[$index].paralelSignAction;
                    	conditionedActionListField.data.isEdit = true;
                    	conditionedActionListField.data.deleteActionsList = [];
                    	conditionedActionListField.data.chkSignatoryType = true;
                    	conditionedActionListField.data.selectReceiverList = [];
                    }
                    var subject = undefined;
                    if(scope.presetSignActionDefinition.presetSignActions[$index].diligenceStampType) {
                    	subject = scope.presetSignActionDefinition.presetSignActions[$index].customText;
                    }
                    modal.annexaFormly.model.modal_body = {
                    		paralelConditionedAction: scope.presetSignActionDefinition.presetSignActions[$index].paralelConditionedAction,
                    		actionOrder: scope.presetSignActionDefinition.presetSignActions[$index].actionOrder,
                    		signActionType: scope.presetSignActionDefinition.presetSignActions[$index].signActionType,
                    		signatoryType: scope.presetSignActionDefinition.presetSignActions[$index].signatoryType,
                    		actionLevel: scope.presetSignActionDefinition.presetSignActions[$index].actionLevel,
                    		thirdAddress: scope.presetSignActionDefinition.presetSignActions[$index].thirdAddress,
                    		maxDurationDays: scope.presetSignActionDefinition.presetSignActions[$index].maxDurationDays,
							signatureWithoutOrder: scope.signatureWithoutOrder,
							diligenceStampType: scope.presetSignActionDefinition.presetSignActions[$index].diligenceStampType,
							subject: subject,
							subjectAux: subject,
							onlyOneSign: scope.presetSignActionDefinition.presetSignActions[$index].onlyOneSign
                    }
                    if(scope.presetSignActionDefinition.presetSignActions[$index].user && !scope.presetSignActionDefinition.presetSignActions[$index].user.user) {
                    	var user = angular.copy(scope.presetSignActionDefinition.presetSignActions[$index].user);
                    	modal.annexaFormly.model.modal_body.user = {
                        		user: {
                        			completeName: user.completeName,
                        			id: user.id,
                        			identifier: user.identifier,
                        			name: user.name,
                        			surename1: user.surename1,
                        			surename2: user.surename2
                        		},
                        		id: user.id,
                        		value: user.completeName
                        };
                    }
                    if(scope.presetSignActionDefinition.presetSignActions[$index].third && !scope.presetSignActionDefinition.presetSignActions[$index].third.third) {
                    	var third = angular.copy(scope.presetSignActionDefinition.presetSignActions[$index].third);
                    	var name = '';
                        if(third.corporateName) {
                            name = third.corporateName;
                        } else {
                            name = third.name + (third.surname1Particles ? ' ' + third.surname1Particles : '') + ' ' + third.surename1;
                        }

                        if(third.surename2) {
                            name += (third.surname2Particles ? ' ' + third.surname2Particles : '') + ' ' + third.surename2;
                        }
                    	modal.annexaFormly.model.modal_body.third = {
                    		third: {
                    			addresses: third.addresses,
                    			completeName: third.completeName,
                    			corporateName: third.corporateName,
                    			id: third.id,
                    			name: third.name,
                    			surname1Particles: third.surname1Particles,
                    			surename1: third.surename1,
                    			surname2Particles: third.surname2Particles,
                    			surename2: third.surename2
                    		},
                    		id: third.id,
                    		value: name
                        };
                    }
                    modal.annexaFormly.model.modal_body.chkSignatoryType = true;
                	var add = function() {
                		var valid = true;
                		var model = this.annexaFormly.model.modal_body;
                		if(model && !model.paralelConditionedAction && model.signActionType && model.signActionType.code == 'THIRDSIGN') {
                			model.user = { user: undefined };
                			model.actionLevel = undefined;
                			model.signatoryType = undefined;
                			model.onlyOneSign = false;
                		} else if(model && !model.paralelConditionedAction && model.signActionType && model.signActionType.code == 'AUTOSIGN_ORGAN') {
                			model.user = { user: undefined };
                			model.actionLevel = undefined;
                			model.signatoryType = undefined;
                			model.third = { third: undefined };
                			model.thirdAddress = undefined;
                			model.onlyOneSign = false;
                			if(model.diligenceStampType.canChange) {
                				scope.presetSignActionDefinition.presetSignActions[$index].customText = model.subject;
                			} else {
                				scope.presetSignActionDefinition.presetSignActions[$index].customText = model.subjectAux;
                			}
                		} else if(model && model.paralelConditionedAction) {
                			if(this.annexaFormly.fields && this.annexaFormly.fields[0] && this.annexaFormly.fields[0].fieldGroup) {
                				var conditionedActionListField = $linq(this.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'conditionedActionList'");
                				if(conditionedActionListField && conditionedActionListField.data && conditionedActionListField.data.actionsList) {
                					if(conditionedActionListField.data.actionsList.length > 0) {
                            			model.user = { user: undefined };
                            			model.actionLevel = undefined;
                            			model.signatoryType = undefined;
                            			model.third = { third: undefined };
                            			model.thirdAddress = undefined;
                            			model.diligenceStampType = undefined;
                            			model.subject = undefined;
                            			model.subjectAux = undefined;
                            			model.paralelSignAction = conditionedActionListField.data.actionsList;
                					} else {
                						valid = false;
                						this.alerts.push({ msg: 'global.documents.modal.sendToSign.errorConditionedSignNoAction' });
                					}
                				}
                				if(conditionedActionListField && conditionedActionListField.data && conditionedActionListField.data.deleteActionsList 
                						&& conditionedActionListField.data.deleteActionsList.length > 0) {
                					_.forEach(conditionedActionListField.data.deleteActionsList, function (value) {
        	                            value.deleted = true;
        	                            value.removedDate = new Date();
        	                            model.paralelSignAction.push(value);
                        			});
                				}
                			}
                		} else {
                			model.third = { third: undefined };
                			model.thirdAddress = undefined;
                			model.onlyOneSign = false;
                			var $rootScope = angular.element(document.body).injector().get('$rootScope');
                			if (model.signatoryType.id == $rootScope.app.configuration.signatory_type_user.value) {
                    			model.actionLevel = undefined;
                            } else if (model.signatoryType.id != $rootScope.app.configuration.signatory_type_user.value) {
                    			model.user = { user: undefined };
                            }
                		}
                		if(valid) {
                			scope.presetSignActionDefinition.presetSignActions[$index].paralelConditionedAction = model.paralelConditionedAction;
                    		scope.presetSignActionDefinition.presetSignActions[$index].actionOrder = model.actionOrder;
                            scope.presetSignActionDefinition.presetSignActions[$index].signActionType = model.signActionType;
                            scope.presetSignActionDefinition.presetSignActions[$index].signatoryType = model.signatoryType;
                            scope.presetSignActionDefinition.presetSignActions[$index].actionLevel = model.actionLevel;
                            scope.presetSignActionDefinition.presetSignActions[$index].user = model.user.user;
                            scope.presetSignActionDefinition.presetSignActions[$index].third = model.third.third;
                            scope.presetSignActionDefinition.presetSignActions[$index].thirdAddress = model.thirdAddress;
                            scope.presetSignActionDefinition.presetSignActions[$index].maxDurationDays = model.maxDurationDays;
                            scope.presetSignActionDefinition.presetSignActions[$index].diligenceStampType = model.diligenceStampType;
                            scope.presetSignActionDefinition.presetSignActions[$index].subject = model.subject;
                            scope.presetSignActionDefinition.presetSignActions[$index].subjectAux = model.subjectAux;
                            scope.presetSignActionDefinition.presetSignActions[$index].onlyOneSign = model.onlyOneSign;
                            if(!scope.presetSignActionDefinition.presetSignActions[$index].paralelConditionedAction) {
                    			_.forEach(scope.presetSignActionDefinition.presetSignActions[$index].paralelSignAction, function (value) {
    	                            value.deleted = true;
    	                            value.removedDate = new Date();
                    			});
                            } else {
                                scope.presetSignActionDefinition.presetSignActions[$index].paralelSignAction = model.paralelSignAction;
                            }
                    		scope.chkAndUpdateActionOrder(model.actionOrder, $index);
                    		if (scope.presetSignActionDefinition.saveActionFunction){
                    			scope.presetSignActionDefinition.saveActionFunction();
                    		} else {
                    			scope.presetSignActionDefinition.presetSignActions[$index].paralelSignAction = $linq(model.paralelSignAction).where("x => !x.deleted").toArray();
                    		}
                    		this.close();
                		}
                	};
                	AnnexaFormlyFactory.showModal("modalEditPresetSignCircuit", modal, add, false);
                }
                scope.removeAction = function(index) {
                	scope.presetSignActionDefinition.presetSignActions.sort(scope.compare);
                	var orderDelete = scope.presetSignActionDefinition.presetSignActions[index].actionOrder;
                    if(scope.presetSignActionDefinition.removeActionFunction){
                    	scope.presetSignActionDefinition.presetSignActions[index].deleted = true;
                    	scope.presetSignActionDefinition.presetSignActions[index].removedDate = new Date();
                    	for(var i = 0; i < scope.presetSignActionDefinition.presetSignActions.length; i++){
                    		var userCircuit = scope.presetSignActionDefinition.presetSignActions[i];
                    		if(orderDelete < userCircuit.actionOrder && orderDelete != userCircuit.actionOrder){
                            	userCircuit.actionOrder = userCircuit.actionOrder - 1;
                            }
                        }
                        scope.presetSignActionDefinition.removeActionFunction();
                    } else {
                    	for(var i = index; i < scope.presetSignActionDefinition.presetSignActions.length; i++) {
                    		scope.presetSignActionDefinition.presetSignActions[i].actionOrder = scope.presetSignActionDefinition.presetSignActions[i].actionOrder -1;
                        }
                    	scope.presetSignActionDefinition.presetSignActions.splice(index, 1);
                    }
                };

                scope.cancelAction = function(index, rowform){
                    if(scope.presetSignActionDefinition.presetSignActions[index]&& !scope.presetSignActionDefinition.presetSignActions[index].id){
                        scope.presetSignActionDefinition.presetSignActions.splice(index, 1);
                    }
                    rowform.$cancel();
                }
                
                scope.chkAndUpdateActionOrder = function(actionOrder, index) {
                	if(actionOrder != null){
                		var existOrder = false;
                		for(var i = 0; i < scope.presetSignActionDefinition.presetSignActions.length; i++){
                            if(i != index && scope.presetSignActionDefinition.presetSignActions[i].actionOrder == actionOrder){
                                existOrder = true;
                                break;
                            }
                        }
                		if(existOrder){
                			if(index + 1 < actionOrder) {
                				for(var i = index; i < actionOrder; i++){
                    				var userCircuit = scope.presetSignActionDefinition.presetSignActions[i];
                    				if(i != index) {
                    					userCircuit.actionOrder = userCircuit.actionOrder - 1;
                    				}
                    			}
                			} else if (index + 1 > actionOrder) {
                				for(var i = 0; i < index; i++){
                    				var userCircuit = scope.presetSignActionDefinition.presetSignActions[i];
                    				if(i != index && userCircuit.actionOrder >= actionOrder) {
                    					userCircuit.actionOrder = userCircuit.actionOrder + 1;
                    				}
                    			}
                			}
                			scope.presetSignActionDefinition.presetSignActions.sort(scope.compare);
                		}
                	}
                }
                
                scope.saveAction = function(data, id) {
                    angular.extend(data, {id: id});
                    if(data.actionOrder != null){
                        if(data.actionOrder !=  scope.presetSignActionDefinition.presetSignActions[id].actionOrder) {
                            var existOrder = false;
                            var orderActualNull = (scope.presetSignActionDefinition.presetSignActions[id].actionOrder == null || scope.presetSignActionDefinition.presetSignActions[id].actionOrder == undefined);
                            for(var i = 0; i < scope.presetSignActionDefinition.presetSignActions.length; i++){
                                if(scope.presetSignActionDefinition.presetSignActions[i].actionOrder == data.actionOrder){
                                    existOrder = true;
                                    break;
                                }
                            }
                            if(existOrder){
                            	var actualOrder = scope.presetSignActionDefinition.presetSignActions[id].actionOrder;
                            	var newOrder = data.actionOrder;
                                for (var i = 0; i <scope.presetSignActionDefinition.presetSignActions.length; i++) {
                                	
                                	var userCircuit = scope.presetSignActionDefinition.presetSignActions[i];
                                	if(!orderActualNull) {
                                		if (userCircuit.idTable != id) {
                                			if(actualOrder < newOrder) {
                                				if(actualOrder < userCircuit.actionOrder && newOrder >= userCircuit.actionOrder) {
                                    				userCircuit.actionOrder = userCircuit.actionOrder - 1;
                                    			}
                                			} else if(actualOrder > newOrder) {
                                				if(actualOrder > userCircuit.actionOrder && newOrder <= userCircuit.actionOrder) {
                                    				userCircuit.actionOrder = userCircuit.actionOrder + 1;
                                    			}
                                			}
                                		} 
                                	}else {
                                		if (userCircuit.idTable != id) {
                                            if(userCircuit.actionOrder > newOrder){
                                                userCircuit.actionOrder = userCircuit.actionOrder + 1;
                                            }else if(userCircuit.actionOrder == newOrder){
                                                if (newOrder == scope.presetSignActionDefinition.presetSignActions.length) {
                                                    userCircuit.actionOrder = userCircuit.actionOrder - 1;
                                                } else {
                                                    userCircuit.actionOrder = userCircuit.actionOrder + 1;
                                                }
                                            }
                                        }
                                	}
                                }
                            }
                        }
                    }
                };
                
                scope.compare = function( a, b ) {
                	if ( a.actionOrder < b.actionOrder ){
                		return -1;
                	}
                	if ( a.actionOrder > b.actionOrder ){
                		return 1;
                	}
                	return 0;
                }
                
                scope.presetSignActionDefinition.presetSignActions.sort(scope.compare);

                scope.afterSaveAction = function(index){
                    if(scope.presetSignActionDefinition.saveActionFunction){
                        scope.presetSignActionDefinition.saveActionFunction(index);
                        scope.presetSignActionDefinition.presetSignActions.sort(scope.compare);
                    }
                }
                scope.checkUserIdentifier = function (data, action) {
                    var $rootScope = angular.element(document.body).injector().get('$rootScope');
                    if(action.signatoryType && action.signatoryType.id == $rootScope.app.configuration.signatory_type_user.value){
                        if (data == null || data == undefined || data == '') {
                            return $filter('translate')('global.validation.required');
                        }else{
                            if(data.object){
                                action.user = data.object;
                            }else{
                                action.user = undefined;
                                return  $filter('translate')('global.validation.required');
                            }
                        }
                    }
                };
                scope.checkActionLevel=function(data, action){
                    var $rootScope = angular.element(document.body).injector().get('$rootScope');
                    if(action.signatoryType && action.signatoryType.id != $rootScope.app.configuration.signatory_type_user.value){
                        if (!data) {
                            return $filter('translate')('global.validation.required');
                        }
                    }
                }
                scope.getUserName = function (user) {
                    if (user && user.user) {
                        var name = user.user.name;
                        if(user.user.surename1){ name = name + " "+user.user.surename1};
                        if(user.user.surename2){name = name + " "+user.user.surename2};
                        return name;
                    } else if(user){
                    	var name = user.name;
                        if(user.surename1){ name = name + " "+user.surename1};
                        if(user.surename2){name = name + " "+user.surename2};
                        return name;
                    } else{
                        return "";
                    }
                };
                
                scope.getThirdName = function (action) {
                	if(action.third && action.thirdAddress) {
                		var name = '';
                        if(action.third.corporateName) {
                            name = action.third.corporateName;
                        } else {
                            name = action.third.name + (action.third.surname1Particles ? ' ' + action.third.surname1Particles : '') + ' ' + action.third.surename1;
                        }

                        if(action.third.surename2) {
                            name += (action.third.surname2Particles ? ' ' + action.third.surname2Particles : '') + ' ' + action.third.surename2;
                        }
                        return name + " (" + action.thirdAddress.telematicValue + ")";
                	} else {
                		return "";
                	}
                };
                
                scope.getAutosignType = function (type) {
                	if(type) {
                		return type[Language.getActiveColumn()];
                	} else {
                		return "";
                	}
                }

 				scope.$on('signatureWithoutOrderSelected', function(event, args) {
					scope.signatureWithoutOrder = ((args && args.item && args.item.id)?true:false);
                });
 				
 				scope.$on('sendAlertProfileSelected', function(event, args) {
					scope.sendAlertProfile = ((args && args.item && args.item.id)?true:false);
                });

                element.html(getTemplate(scope.presetSignActionDefinition)).show();
                $compile(element.contents())(scope);
            }
        }

    }]);