/**
 * Created by osirvent on 01/08/2016.
 */
angular.module('annexaApp')
    .directive('annexaBox',['$compile', '$filter', 'Language', '$rootScope', function ($compile, $filter, Language, $rootScope) {
        var getTemplate = function(scope) {
            var sButtonText = 'global.literals.add';
            var onlyHeader = '';
            var noSearch = '';
            if(scope && scope.searchButtonText) {
                sButtonText = scope.searchButtonText;
            }
            if (!(scope && scope.search) && !(scope && scope.new)) { // Only header Box, no search, no new buttons
                onlyHeader = ' alone-header';
            } else {
                onlyHeader = ' flex-align-self-start';
            }
            if (!(scope && scope.search)) {
                noSearch = ' no-search'; // No search box, only new button
            }

            var template = '';

            template += '<div class="box">';
            template += '   <div class="box-header box-header-flex p-y-0 p-r-sm">';
            // BOX TITLE
            if(scope.paginationAnchor) {
                template += '       <h3 id="box-header-'+scope.paginationAnchor+'" class="annexa-box-header box-header-flex__title p-y text-nowrap m-r-md' + onlyHeader + ' ">{{definition.title | translate}}</h3>';
            }else{
                template += '       <h3 class="annexa-box-header box-header-flex__title p-y text-nowrap m-r-md' + onlyHeader + ' ">{{definition.title | translate}}</h3>';
            }
            // BOX SEARCH ROW
            if ((scope && scope.search) || (scope && scope.new)) {

                if (scope && scope.showTemplates) {
					template += '       <div class="box-tool box-tool-no-max-with box-header-flex__search box-header-flex__search--long m-y-xs' + noSearch + '">';
				}else{
					template += '       <div class="box-tool box-header-flex__search box-header-flex__search--long m-y-xs' + noSearch + '">';
                }
				template += '         <ul class="m-t-xs">';
                if (scope && scope.search) {
                    template += '       <li class="searchBox" annexa-permission annexa-permission-only="definition.viewPermission">';
                    template += '           <div class="input-group box-tool-group" >';
                    template += '              <span class="input-group-btn">';
                    template += '                  <span class="btn-sm p-l-xs p-r-0 text-grey" ><i class="fa fa-search" aria-hidden="true"></i></span>';
                    template += '              </span>';
                    template += '              <div class="ico-floating-wrapper">';
                    if (scope.typeAheadSelect) {
                        template += '              <input type="text" class="form-control-sm b-a-0" ng-model="definition.searchedValue" aria-label="{{\'global.literals.search\' | translate}}" placeholder="{{definition.placeholderSearch | translate}}" uib-typeahead="result as result.value for result in definition.search({val: $viewValue})" typeahead-loading="loadingBox" typeahead-no-results="noResultBox" typeahead-min-length="3" typeahead-wait-ms="1000" typeahead-editable="false" typeahead-focus-first="true" typeahead-on-select="definition.typeAheadSelect($item)" />';
                    } else {
                        template += '              <input type="text" class="form-control-sm b-a-0" ng-model="definition.searchedValue" aria-label="{{\'global.literals.search\' | translate}}" placeholder="{{definition.placeholderSearch | translate}}" uib-typeahead="result as result.value for result in definition.search({val: $viewValue})" typeahead-loading="loadingBox" typeahead-no-results="noResultBox" typeahead-min-length="3" typeahead-wait-ms="1000" typeahead-editable="false" typeahead-focus-first="true" />';
                    }
                    template += '              <span class="ico-floating" aria-hidden="true">';
                    template += '                  <i ng-show="loadingBox" class="fa fa-spinner fa-pulse "></i>';
                    template += '                  <i ng-show="noResultBox" class="fa fa-ban "></i>';
                    template += '              </span>';
                    template += '              </div>';
                    if (scope.searchAdd) {
                        template += '           <span class="input-group-btn">';
                        template += '               <button class="btn-sm btn-accept btn" ng-click="definition.searchAdd()" translate="' + sButtonText + '" >' + sButtonText + '</button>';
                        template += '           </span>';
                    }
                    template += '           </div>';
                    if (scope.advancedSearch) {
                    	template += '         <button class="text-primary cursiva-grey no-bg no-border pull-left" ng-click="definition.advancedSearch()"><span translate="global.literals.advanced_search">Busqueda avanzada</span></button> ';
                    }
                    template += '       </li>';
                }
                if (scope && scope.new) {
                    template += '       <li class=" inline" ng-if="definition.new && !definition.showNews"  annexa-permission annexa-permission-only="definition.newPermission">';
                    template += '           <button type="button" class=" btn-sm grey btn-new text-white" ng-click="definition.new()" translate="global.literals.new" role="button">New</button>';
                    template += '       </li>';

                    template += '       <li class=" inline" ng-if="definition.new && definition.showNews"  annexa-permission annexa-permission-only="definition.newPermission">';
                    template += '           <div class=" btn-group  dropdown " role="group">';
                    template += '               <a class="btn grey btn-new text-white" href="" ng-click="definition.new()">';
                    template += '                   <span translate="global.literals.new">New</span>';
                    template += '               </a>';
                    template += '               <div class="btn-group btn-b-l " role="group">';
                    template += '                   <button class="grey btn-new dropdown-toggle btn p-x-xs" data-toggle="dropdown" title="{{ \'global.literals.showOptions\' | translate }}"><span class="sr-only">{{ \'global.literals.showOptions\' | translate }}</span></button>';
                    template += '                   <div class="dropdown-menu grey text-white pull-right">';
                    template += '                       <a class="btn-sm grey btn-new text-white dropdown-item " href="" ng-click="definition.news()">';
                    template += '                           <span translate="global.buttons.document.news">News</span>';
                    template += '                       </a>';
                    template += '                       <a class="btn-sm grey btn-new text-white dropdown-item" ng-if="definition.showNewInteroperable" href="" ng-click="definition.newInteroperable()"  annexa-permission annexa-permission-only="\'add_document_via_oberta\'">';
                    template += '                           <span translate="global.buttons.document.interoperable">Interoperable</span>';
                    template += '                       </a>';
                    template += '                       <a class="btn-sm grey btn-new text-white dropdown-item" ng-if="definition.showAssociateThirdDocuments && definition.associateThirdDocuments" href="" ng-click="definition.associateThirdDocuments()">';
                    template += '                           <span translate="global.buttons.document.associateThirdDocuments">Associate third documents</span>';
                    template += '                       </a>'; 
                    template += '                   </div>';
                    template += '               </div>';
                    template += '           </div>';
                    template += '       </li>';
                }
                if (scope && scope.showTemplates) {
                    template += '       <li class="inline" ng-if="definition.showTemplates" annexa-permission annexa-permission-only="definition.newPermission">';
                    template += '           <button type="button" class=" btn-sm grey btn-new text-white pull-left" ng-click="definition.fromTemplate()" translate="global.literals.template" >New</button>';
                    template += '       </li>';
                }
                if (scope && scope.new && $rootScope.app.configuration.load_documents_from_scan_folder.value) {
                    template += '       <li ng-if="definition.showScanner" class="inline" annexa-permission annexa-permission-only="definition.newPermission">';
                    template += '           <button type="button" class=" btn-sm grey btn-new text-white pull-left" ng-click="definition.fromScanner()" translate="global.literals.fromScanner" >Scanner</button>';
                    template += '       </li>';
                }
                template += '         </ul>';
                template += '       </div>';
                /* /box-tool */

            }
            template += '   </div>'; /* /box-header */

            template += '   <div class="box-body">';
            template += '   </div>';
            if(scope && scope.pagination) {
                template += '   <div ng-if="definition.content.length > definition.itemsPage" class="box-footer annexa-box-footer">';
                template += '               <annexa-object-box-pagination items-page="definition.itemsPage" page="definition.page" anchor="{{definition.paginationAnchor}}" content="definition.content"></annexa-object-box-pagination>';
                template += '   </div>';
            }
            template += '</div>';
            return template;
        };

        return {
            restrict: 'E',
            replace: true,
            scope:{
                definition: '='	
            },
            transclude: true,
            link: function(scope, element, attr, ctrl, transclude) {

                element.html(getTemplate(scope.definition)).show();

                transclude(scope.$parent, function (clone, scope) {
                    element.find('.box-body').append(clone);
                });

                $compile(element.contents())(scope);
            }
        };

    }])
    .directive('annexaBoxFilter',['$compile', '$rootScope', 'Language', 'HelperService', function($compile, $rootScope, Language, HelperService) {
    	
        var getTemplate = function () {
            var template = '';

            template += '<div class="modal-dialog {{modal.size}}">';
            template += '   <div class="modal-content">';
            template += '       <div class="modal-header">';
            template += '           <div class="row">';
            template += '               <div class="col-sm-12">';
            template += '                   <h5 class="modal-title" tabindex="0"><i class="fa fa-search" aria-hidden="true"></i> {{modal.title | translate}}</h5>';
            template += '               </div>';
            template += '           </div>';
            template += '       </div>';
            template += '       <div class="modal-body p-lg">';
            template += '           <div class="filterbar">';
            template += '               <div class="container-fluid">';
            template += '                   <div class="row">';
            template += '                       <tablefilter id="tFilter_{{modal.id}}" filter-object="modal.tFilter" filter-function="search()" advanced-search="true"/>';
            template += '                   </div>';
            template += '               </div>';
            template += '           </div>';
            
            template += '           <div class="p-y-md">';
            template += '               <div class="white pos-relative m-b">';
            
            template += '                   <div class="table-responsive annexa-table-responsive" id="dtaTableInput">';
            template += '                       <tabledata id="tableAdvancedSearch" tabledata-definition="tabledataDefinition" class="table table-striped b-t b-b"/>';
            template += '                   </div>';
            template += '               </div>';
            template += '           </div>';
            template += '       </div>';
            
            template += '       <div class="modal-footer">';  
            template += '           <button class="btn btn-sm primary" ng-click="addSelected(radioSelected)" data-ng-disabled="!addDisabled()"><i class="fa fa-plus" aria-hidden="true"></i><span translate="global.literals.add">Add</span></button>';
            template += '           <button type="button" class="btn btn-sm grey-500  text-white" data-dismiss="modal" ng-click="closeModal()"><span translate="global.literals.cancel">Close</span></button>';
            template += '       </div>';

            template += '   </div>';
            template += '</div>';

            return template;
        };

        return {
            restrict: 'E',
            replace: true,
            scope: {
                modal: '=modalObject'
            },
            link: function (scope, element, attr) {
                scope.modal.tFilter.clearFilters();
                scope.emptyFilter = true;
                scope.selectAll = false;
                scope.aside = {title: 'Title', content: 'Hello Aside<br />This is a multiline message!'};

                scope.showDatatable = false;

                scope.search = function() {
                    scope.emptyFilter = scope.modal.tFilter.checkEmpty();
                    scope.modal.firstTime = false;
                    scope.tableAdvancedSearch.reloadInternalData(true);
                    scope.modal.tFilter.showLegend = true;
                };

                scope.closeModal = function(){
                    scope.modal.tFilter.clearFilters();
                    scope.modal.close();
                };

                scope.addElementToTable = scope.modal.addElementToTable;

                // Assign selected cell to box table
                scope.assignCell = function (objId) {

                    var aux = -1;

                    aux = HelperService.findFirstElementWhere(this.tableAdvancedSearch.list, 'id', objId, -1);

                    if(aux != -1) {
                        if(scope.addElementToTable) {
                            this.searchedValue = {id: objId, object: JSOG.encode(aux), value: undefined};
                            scope.addElementToTable();
                        } else if(scope.modal.externalAdd) {
                            scope.modal.externalAdd({ id: objId, object: JSOG.encode(aux), value: undefined }, scope.modal.externalObject);
                        }
                    }
                };

                scope.tabledataDefinition = scope.modal.tabledataDefinition;
                var filterCallFunction = function(){
                	var filterCallToReturn = scope.modal.tFilter.getFilterCall();
                	if(scope.modal.addFilterCall){
                		if(filterCallToReturn){
                			for (var property in scope.modal.addFilterCall) {
                			    if (scope.modal.addFilterCall[property]) {
                			    	filterCallToReturn[property] = scope.modal.addFilterCall[property];
                			    }
                			}
                    	}else{
                    		filterCallToReturn = scope.modal.addFilterCall;
                    	}
                	}
                	return filterCallToReturn;
                	
                }
                var filterCallAuxFunction = function(){
                	var filterCallAuxToReturn = scope.modal.tFilter.getFilterCallAux();
                	filterCallAuxToReturn.loggedUser = $rootScope.LoggedUser.id;
                	filterCallAuxToReturn.langColumn = Language.getActiveColumn();
                	filterCallAuxToReturn.added = scope.modal.added;
                	if(scope.modal.assignedTask){
                		filterCallAuxToReturn.assignedTask = scope.modal.assignedTask;
                	}
                	return filterCallAuxToReturn;
                	
                }
                scope.tableAdvancedSearch =  {
                    dtColumns: scope.modal.tabledataColumns,
                    filterCall: filterCallFunction(),
                    filterCallAux: filterCallAuxFunction(),
                    reloadInternalData: function (resetPaging) {
                        this.filterCall = scope.modal.tFilter.getFilterCall();
                        if(scope.modal.addFilterCall && !scope.emptyFilter) {
                            Object.assign(this.filterCall, scope.modal.addFilterCall);
                        }
                        this.filterCallAux = scope.modal.tFilter.getFilterCallAux();
                        this.filterCallAux.loggedUser = $rootScope.LoggedUser.id;
                        this.filterCallAux.langColumn = Language.getActiveColumn();
                        this.filterCallAux.added = scope.modal.added;
                    	if(scope.modal.assignedTask){
                    		this.filterCallAux.assignedTask = scope.modal.assignedTask;
                    	}
                        if (this.filterCallAux.identificationDocumentType){
                            this.filterCallAux.identificationDocumentType = this.filterCallAux.identificationDocumentType.id;
                        }
                        if (this.filterCallAux.thirdType){
                            this.filterCallAux.thirdType = this.filterCallAux.thirdType.id;
                        }
                        if(this.filterCallAux.expired){
                            if(this.filterCallAux.expired.id == 'expired'){
                                this.filterCallAux.expired = true;
                            }
                            else if(this.filterCallAux.expired.id == 'noExpired'){
                                this.filterCallAux.expired = false;
                            }
                            else {
                                this.filterCallAux.expired = undefined;
                            }
                        }
                        if(this.filterCallAux.langColumn){
                            this.filterCallAux.langColumn = Language.getActiveColumn();
                        }
                        if(this.filterCallAux.tramitation && !this.filterCallAux.openDossierTransaction){
                            this.filterCallAux.openDossierTransaction = {"id":"1"};
                        }
                        if(scope.modal.addFilterCallAux){
                            scope.modal.addFilterCallAux(this.filterCall, this.filterCallAux);
                        }
                        if(this.table && this.table.reloadData) {
                            this.table.reloadData(function callbackInstance(json) {
                                scope.showDatatable = true;
                            }, resetPaging);
                        }
                    }
                };
                
                scope.selectedRows = [];
                scope.rowSelected = {};
                
                // Cuando pulsamos sobre un checkbox
                scope.selectRow = function(id) {
                	
                    var indexSelected = $linq(scope.selectedRows).indexOf(" x => x.id == " + id);

                    if (indexSelected != -1) {
                    	scope.selectedRows.splice(indexSelected, 1);
                    } 
                    
                    // entramos aqui cuando deseleccionamos un checkbox
                    else {
                    	var obj = $linq(angular.copy(scope.tableAdvancedSearch.list)).firstOrDefault(undefined, "x => x.id == "+ id);
                    	if(obj){
                    		scope.selectedRows.push(obj);
                    	}
                    }
                    if(scope.selectedRows && scope.tableAdvancedSearch && scope.tableAdvancedSearch.list && scope.selectedRows.length == scope.tableAdvancedSearch.list.length){
                    	scope.selectAll = true;
                    }else{
                    	scope.selectAll = false;
                    }
                }
                
                scope.selectAllRows = function() {
                	scope.selectedRows.length = 0;
                	if(scope.selectAll){
                		_.forEach(scope.tableAdvancedSearch.list, function(object){
                			scope.selectedRows.push(object);
                			if(!scope.rowSelected){
                				scope.rowSelected = {};
                			}
                			scope.rowSelected[object.id] = true;
                		});
                	}else{
                		if(scope.rowSelected){
                			var keys = Object.keys(scope.rowSelected);
                            _.forEach(keys, function(key){
                            	scope.rowSelected[key] = false;
                            });
                		}
                	}
                }
                
                // funcion para habilitar o deshabilitar el boton de A�adir
                scope.addDisabled = function(){
                	if($linq(Object.keys(scope.rowSelected)).count(function(x){ return scope.rowSelected[x];}) > 0){
                		return true;
                	}
                	else{
                		if(scope.radioSelected){
                			return true;
                		}
                		else{
                			return false;
                		}
                	}
                }
                
                // Cuando pulsamos en el boton de A�adir
                scope.addSelected = function(radioSelected) {
                	
                	// para saber si viene de un radio
                	if(radioSelected){
	                    var aux = -1;
	                    aux = $linq(angular.copy(scope.tableAdvancedSearch.list)).firstOrDefault(undefined, "x => x.id == "+ radioSelected);
	
	                    if(aux) {
	                        if(scope.addElementToTable) {
	                            this.searchedValue = {id: radioSelected, object: JSOG.encode(aux), value: undefined};
	                            scope.addElementToTable();
	                        } else if(scope.modal.externalAdd) {
	                            scope.modal.externalAdd({ id: radioSelected, object: JSOG.encode(aux), value: undefined }, scope.modal.externalObject);
	                        }
	                    }
                	}
                	
                	// para saber si viene de un checkbox
                	else{
	                    scope.selected = [];
	                    var aux = -1;
	                    var addedObjects = [];
	                    var id = undefined;
	                    _.forEach(scope.selectedRows, function (item) {
                        	addedObjects.push(JSOG.encode(item));
	                    });
	                    if(addedObjects && addedObjects.length > 0){
	                    	if(scope.addElementToTable){
	                    		scope.searchedValue = {id: addedObjects.length, object: addedObjects, value: undefined};
                                scope.addElementToTable();
	                    	}else if(scope.modal.externalAdd){
	                    		scope.modal.externalAdd({ id: id, object: addedObjects, value: undefined }, scope.modal.externalObject);
	                    	}
	                    }
                	}
                }

                element.html(getTemplate()).show();

                $compile(element.contents())(scope);
            }
        };
    }])
    .directive('annexaDeleteButton',['$compile', function($compile){
        var getTemplate = function(scope) {
            var template = '';
            if(scope.deleteFunction){
            	if(scope.deleteObject && scope.deleteObject.currentDocument){
            		template = '<button class="md-btn p-a-0 md-flat md-btn__star grey-pencil" ng-click="deleteFunction(deleteObject.id, deleteObjectIndex)"  title="{{ \'global.literals.removeRelationDocument\' | translate}}"><i class="fa fa-remove" aria-hidden="true"></i><span class="sr-only">{{ \'global.literals.removeRelationDocument\' | translate}}</span></button>';
            	}else{
            		template = '<button class="md-btn p-a-0 md-flat md-btn__star grey-pencil" ng-click="deleteFunction(deleteObject.id, deleteObjectIndex)"  title="{{ \'global.literals.remove\' | translate}}"><i class="fa fa-remove" aria-hidden="true"></i><span class="sr-only">{{ \'global.literals.remove\' | translate}}</span></button>';
            	}
            }
            return template;
        };

        return {
            restrict: 'E',
            replace: true,
            scope:{
                deleteObjectIndex:'=',
                deleteObject:'=',
                deleteFunction: '=',
            },
            transclude: true,
            link: function(scope, element, attr, ctrl, transclude) {
                element.html(getTemplate(scope)).show();
                transclude(scope.$parent, function (clone, scope) {
                    element.find('.box-body').append(clone);
                });
                $compile(element.contents())(scope);
            }
        }
    }])
    .filter('startFrom', function() {
        return function(input, start) {
            start = +start; //parse to int
            return input.slice(start);
        }
    })
    .component('annexaObjectBoxPagination',{
    templateUrl: './scripts/components/box/annexa-box-pagination.html',
    controller:['$document', '$scope', function ($document, $scope) {
        var vm = this;

        var calculateTotalPages = function () {
            return Math.floor((vm.content.length -1) / vm.itemsPage);
        }

        var scrollToTitle = function() {
        }

        vm.minusPage = function() {
            if(vm.page > 0) {
                vm.page--;
                scrollToTitle();
            }
        }

        vm.plusPage = function () {
            if(vm.page < vm.totalPages) {
                vm.page++;
                scrollToTitle();
            }
        }

        vm.setPage = function(num) {
            vm.page = num;
            scrollToTitle();
        }

        this.$onInit = function () {
            if(!vm.itemsPage) {
                vm.itemsPage = 10
            }

            vm.page = 0;
            vm.totalPages = calculateTotalPages();

            vm.collectionPages = [];
            for(var i = 0; i <= vm.totalPages; i++) {
                vm.collectionPages.push(i);
            }

            $scope.$on('addRemovedObjectEvent', function(event, args) {
                if(args.addRemoveObject) {
                    vm.totalPages = calculateTotalPages();

                    if(vm.collectionPages) {
                        vm.collectionPages.length = 0;
                    }else{
                        vm.collectionPages = [];
                    }
                    for(var i = 0; i <= vm.totalPages; i++) {
                        vm.collectionPages.push(i);
                    }
                    if(vm.page > vm.totalPages){
                        vm.page = vm.totalPages;
                    }
                }
            });
        }
    }],
    bindings: {
        itemsPage: '=?',
        page: '=',
        anchor: '@',
        content: '='
    }
})
        .directive('annexaEditButton', ['$compile', function ($compile) {
        var getTemplate = function(scope) {
            var template = '';
            if(scope.editFunction){
                template = '<button class="md-btn p-a-0 md-flat md-btn__star grey-pencil" ng-click="editFunction(editObject.id, editObjectIndex)"><i class="fa fa-edit" title="{{ \'global.literals.edit\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{ \'global.literals.edit\' | translate}}</span></button>';
            }
            return template;
        };

        return {
            restrict: 'E',
            replace: true,
            scope:{
                editObjectIndex:'=',
                editObject:'=',
                editFunction: '='
            },
            transclude: true,
            link: function(scope, element, attr, ctrl, transclude) {
                element.html(getTemplate(scope)).show();
                transclude(scope.$parent, function (clone, scope) {
                    element.find('.box-body').append(clone);
                });
                $compile(element.contents())(scope);
            }
        };
    }]);